<template>
  <div ref="root" class="sp-app-filter">
    <template v-if="isVariantFilterBar">
      <component
        :is="FilterBarWrapper"
        ref="filterInstance"
        v-model="model"
        horizontal-align="left"
        :filter="filter"
        :open="open"
        :close-on-select="closeOnSelect"
        @update:open="open = $event"
        @update:active="active = $event"
      />
    </template>

    <template v-else>
      <component
        :is="filter"
        ref="filterInstance"
        v-model="model"
        :open="open"
        :label="label"
        :close-on-select="closeOnSelect"
      />
    </template>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useExpose } from "../../composables/expose";
import { toBoolean, trimToUndefined } from "../../utils/props";
import InsurancePlanFilter from "../shared/components/InsurancePlanFilter.ce.vue";
import FilterBarWrapper from "./components/FilterBarWrapper.ce.vue";

const availableFilters = {
  insurance_plan: InsurancePlanFilter,
};

const emit = defineEmits(["update-active", "update-open", "change", "close"]);

const props = defineProps({
  /**
   * The form element name of the component.
   */
  name: {
    type: String,
    default: "app-filter",
  },
  /**
   * The label of the activator.
   *
   * @type {String}
   */
  label: {
    type: String,
    default: undefined,
  },
  variant: {
    type: String,
    default: "default",
    validator: (value) => ["default", "filter-bar"].includes(value),
  },
  open: {
    type: [Boolean, String],
    default: false,
  },
  closeOnSelect: {
    type: [Boolean, String],
    default: false,
  },
});

const model = ref(undefined);
watch(model, (value) => {
  emit("change", value);
});

const isVariantFilterBar = computed(() => props.variant === "filter-bar");

const filter = computed(() => availableFilters[props.name?.trim()]);

const active = ref(false);

watch(active, (value) => {
  emit("update-active", value);
});

const open = ref(toBoolean(props.open));
watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

const root = ref(null);

watch(open, (value) => {
  emit("update-open", value);

  if (value && isVariantFilterBar.value) {
    /**
     * Scroll the filter into view when the filter bar is opened.
     */
    scrollIntoViewIfNeeded();
  } else if (!value) {
    removeOpenAttribute();
    emit("close");
  }
});

/**
 * Remove the open attribute from the root element.
 * This is necessary to keep the reactivity of the open attribute.
 *
 * TODO: Remove once the filter menu is migrated to the new filter.
 */
function removeOpenAttribute() {
  root.value.getRootNode()?.host?.removeAttribute("open");
}

function scrollIntoViewIfNeeded() {
  root.value.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
}

const filterInstance = ref(null);
const filterInstanceProps = computed(() => filterInstance.value?.component?.props);

const { exposeProperties, exposeMethods } = useExpose(root);

/**
 * Compatability with the filter menu.
 * TODO: Remove once all filters are migrated to the new filter.
 */
exposeProperties({
  isFilterApp: { value: true },
  prependIcon: {
    get: () => filterInstanceProps.value?.prependIcon,
  },
  value: {
    set: (value) => (model.value = trimToUndefined(value)),
    get: () => model.value,
  },
  active: {
    get: () => active.value,
  },
});

/**
 * Compatability with the filter menu.
 * TODO: Remove once all filters are migrated to the new filter.
 */
exposeMethods({
  deactivate() {
    model.value = undefined;
  },
});
</script>
<style>
:host(:focus),
:host(:focus-visible) {
  outline: none;
  box-shadow: none !important;
}
</style>
