<template>
  <div class="sp-modal">
    <slot name="activator" @click.stop="open = true" />

    <sp-dialog v-bind="$props" :open="open" @close="handleDialogClose" @update-open="handleDialogUpdateOpen">
      <sp-card :subtitle="subtitle">
        <div slot="title" class="sp-modal-header">
          <slot name="title">
            <sp-card-title v-if="modalTitle">{{ modalTitle }}</sp-card-title>
          </slot>
          <sp-button
            v-if="!persistent"
            icon="close-bold"
            size="default"
            class="close-button"
            color="default"
            @click="handleDialogClose"
          ></sp-button>
        </div>

        <div slot="subtitle">
          <slot name="subtitle" />
        </div>

        <sp-card-content>
          <slot />
        </sp-card-content>

        <sp-card-actions>
          <slot name="actions" />
        </sp-card-actions>
      </sp-card>
    </sp-dialog>
  </div>
</template>

<script setup>
/**
 * The `sp-modal` component is a wrapper around sp-dialog that provides a default layout for the dialog content.
 * It includes a header with a title and a close button, a subtitle, and a slot for the main content.
 * It also provides a slot for the actions that will be displayed at the bottom of the dialog.
 * It also provides a slot for the activator element that will open the dialog when clicked.
 *
 * @slot activator - The element that will open the dialog when clicked.
 * @slot modalTitle - The title of the dialog.
 * @slot subtitle - The subtitle of the dialog.
 * @slot actions - The actions that will be displayed at the bottom of the dialog.
 *
 * @emits close - Emitted when the dialog is closed.
 * @emits update-open - Emitted when the open property is updated.
 */
import { ref, watch } from "vue";
import { toBoolean } from "../../utils/props";
import { props as dialogProps } from "../SpDialog/props.js";

const emit = defineEmits(["close", "update-open"]);

const props = defineProps({
  ...dialogProps,
  modalTitle: {
    type: String,
    default: undefined,
  },
  subtitle: {
    type: String,
    default: undefined,
  },
});

const persistent = ref(toBoolean(props.persistent));

const open = ref(toBoolean(props.open));

watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

watch(open, (value) => {
  emit("update-open", value);
  if (!value) {
    emit("close");
  }
});

function handleDialogClose() {
  open.value = false;
}

function handleDialogUpdateOpen({ detail }) {
  const [value] = detail;
  open.value = value;
}
</script>

<style>
:host {
  display: block;
  --modal-header-background-color: var(--sp-comp-modal-header-background-color, var(--sp-sys-color-primary));
  --modal-header-color: var(--sp-comp-modal-header-color, var(--sp-sys-color-on-primary));
}
</style>

<style scoped lang="scss">
.sp-modal-header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background-color: var(--modal-header-background-color);
  color: var(--modal-header-color);
  padding: var(--sp-comp-modal-header-padding, var(--sp-comp-dialog-padding, 1rem));
  font-size: var(--sp-comp-modal-header-font-size, 1.125rem);
  font-weight: var(--sp-comp-modal-header-font-weight, var(--sp-sys-font-weight-bold));
  font-family: var(--sp-comp-modal-header-font-family, var(--sp-sys-font-family-bold));
  border-bottom: var(--sp-comp-modal-header-border-bottom, none);
}

.close-button {
  --sp-ce-button-bg-color: var(--sp-comp-modal-close-button-bg-color, var(--modal-header-background-color));
}

.close-button::part(icon) {
  --fill: var(--sp-comp-modal-close-button-icon-fill, var(--modal-header-color));
}

.close-button:focus {
  --sp-ce-button-focus-opacity: 0;
}
</style>
