<template>
  <div class="slider-pagination-controls">
    <sp-button
      v-for="item in items"
      :key="getItemValue(item)"
      class="slide-control"
      icon="ellipse-filled"
      size="xx-small"
      color="default"
      :disabled="isSelected(item)"
      @click="selectItem(item)"
    ></sp-button>
  </div>
</template>

<script setup>
import { onKeyStroke } from "@vueuse/core";

const emit = defineEmits(["update-selected"]);

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  selected: {
    type: Object,
    default: null,
  },
  itemValue: {
    type: String,
    default: "id",
  },
  maxVisibleItems: {
    type: Number,
    default: 6,
  },
});

onKeyStroke("ArrowLeft", previous);
onKeyStroke("ArrowRight", next);

function previous() {
  applyMovement(-1);
}

function next() {
  applyMovement(1);
}

function applyMovement(movement) {
  const index = props.items?.findIndex(isSelected);
  if (index === -1) {
    return;
  }
  const newIndex = index + movement;
  if (newIndex < 0 || newIndex >= props.items.length) {
    return;
  }
  selectItem(props.items[newIndex]);
}

function getItemValue(item) {
  return item[props.itemValue];
}

function isSelected(item) {
  if (!props.selected) {
    return false;
  }
  return getItemValue(props.selected) === getItemValue(item);
}

function selectItem(item) {
  emit("update-selected", item);
}
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.slider-pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
  height: var(--sp-button-size, auto);
  margin-right: 0.25rem;
}
.slide-control {
  --sp-ce-button-bg-color: transparent;
  --sp-ce-button-disabled-cursor: default;
  --sp-ce-button-disabled-text-color: var(--sp-sys-color-primary);
  --sp-ce-button-disabled-opacity: 1 !important;
  --sp-ce-button-text-color: #c9c9c9;

  &::part(icon) {
    --size: 0.625rem;
  }
}
</style>
