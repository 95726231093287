<template>
  <div class="filter-bar-wrapper" :class="classModifiers">
    <component
      :is="filter"
      ref="component"
      v-model="model"
      class="--variant-filter-bar"
      hide-pointer
      location="bottom-left"
      :open="open"
      @update:open="open = $event"
    >
      <template #activator="activatorProps">
        <FilterBarWrapperActivator v-bind="activatorProps" :active="isActive" />
      </template>
    </component>

    <sp-button
      v-if="isActive"
      class="close-action"
      icon="close-bold"
      color="white"
      size="xx-small"
      @click="model = undefined"
    />
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import FilterBarWrapperActivator from "./FilterBarWrapperActivator.ce.vue";

const emit = defineEmits(["update:open", "update:active", "update:model-value"]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean, Object, Array],
    default: undefined,
  },
  filter: {
    type: Object,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const model = ref(props.modelValue);
watch(
  () => props.modelValue,
  (value) => {
    model.value = value;
  },
);
watch(model, (value) => {
  emit("update:model-value", value);
});

const isActive = computed(() => model.value !== undefined);
watch(isActive, (value) => {
  emit("update:active", value);
});

const open = ref(props.open);
watch(
  () => props.open,
  (value) => {
    open.value = value;
  },
);
watch(open, (value) => {
  emit("update:open", value);
});

const classModifiers = computed(() => ({
  "--is-active": isActive.value,
}));

const component = ref(null);
defineExpose({
  component,
});
</script>

<style scoped>
.filter-bar-wrapper {
  position: relative;
}

.close-action {
  display: var(--filter-bar-close-action-display, block);
  position: absolute;
  right: 4px;
  top: 4px;
  --text-color: rgba(0, 129, 138, 0.3);
}
.--variant-filter-bar {
  --sp-comp-popup-margin-top: 0.5rem;
}
</style>
