import { onMounted, ref } from "vue";

export function useHost(root) {
  const host = ref(null);

  onMounted(() => {
    host.value = root.value.getRootNode()?.host;

    if (!host.value) {
      throw new Error("Host not found");
    }
  });

  return {
    host,
  };
}
