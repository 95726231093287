<template>
  <sp-alert v-if="showAlert" :type="model.type" class="insurance-alert-tiny-layout" variant="text">
    <CompactLayoutContent :model="model" />
  </sp-alert>
</template>

<script setup>
import { computed } from "vue";
import CompactLayoutContent from "./CompactLayoutContent.ce.vue";

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
});

const showAlert = computed(() => !props.model.isSelfPay && !props.model.isUndefined);
</script>
<style lang="scss" scoped>
.insurance-alert-tiny-layout {
  --alert-prepend-icon-size: 1em;
  --alert-prepend-icon-font-size: 1.3em;
  --alert-display: inline-grid;
  --alert-append-icon-fill: var(--sp-sys-color-link);
  --alert-prepend-icon-offset: 0;
  --alert-column-gap: 0.25rem;
  --alert-append-column-gap: 0.25rem;

  display: flex;
  position: relative;
  align-items: flex-start;
  min-height: calc(var(--sp-ref-spacing-6) * 2);
}
</style>
