<template>
  <div ref="root" class="root" :class="rootModifierClass">
    <sp-button variant="outlined" @click="open = true">
      View All {{ model.provider.insurancePlanCount }} Accepted Plans
    </sp-button>

    <template v-if="isGtSmScreen">
      <sp-modal
        :open="open"
        width="40ch"
        height="90vh"
        modal-title="All Accepted Insurance Plans"
        @close="open = false"
      >
        <InsurancePlanFilter v-bind="insurancePlanFilterProps" max-height="90vh" />
      </sp-modal>
    </template>

    <template v-else>
      <InsurancePlanFilter v-bind="insurancePlanFilterProps" :open="open" @update:open="open = $event" />
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useBreakpoints } from "../../../composables/breakpoints";
import InsurancePlanFilter from "../../shared/components/InsurancePlanFilter.ce.vue";

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  physicianPath: {
    type: String,
    required: true,
  },
});

const root = ref(null);
const { isGtSmScreen } = useBreakpoints(root);

const rootModifierClass = computed(() => ({
  "--bp-lt-sm": !isGtSmScreen.value,
}));

const open = ref(false);
const url = computed(() => `${props.physicianPath}/insurance_plans`);

const insurancePlanFilterProps = computed(() => ({
  hideProgressBar: true,
  hideTitleBar: true,
  hidePointer: true,
  permanent: true,
  readonly: true,
  width: "100%",
  url: url.value,
}));
</script>

<style scoped>
.root.--bp-lt-sm {
  width: 100%;
  sp-button {
    --button-width: 100%;
  }
}

.filter {
  --sp-comp-list-item-prepend-margin-left: 0;
  --sp-comp-quantum-select-content-padding: 0;
  --divider-display: none;
  --divider-left: 0;
  --divider-width: 100%;
}
</style>
