import { computed } from "vue";
import { useModifiers } from "./classes";

export function useSize(props) {
  const { modifiers } = useModifiers(props, "size");
  const sizeModifiers = computed(() => modifiers.value);

  return {
    sizeModifiers,
  };
}
