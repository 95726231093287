<template>
  <div ref="popup" class="sp-popup" :class="modifiersClass">
    <div class="sp-popup__pointer"></div>
    <div class="sp-popup__content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { useElementBounding, useWindowSize, watchOnce } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  active: {
    type: [Boolean, String],
    default: false,
  },
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  location: {
    type: String,
    default: "bottom",
  },
  horizontalAlign: {
    type: String,
    default: undefined,
    validator: (value) => ["left", "right"].includes(value),
  },
  pointer: {
    type: [Boolean, String],
    default: false,
  },
  pointerPosition: {
    type: String,
    default: "center",
    validator: (value) => ["left", "center", "right"].includes(value),
  },
  permanent: {
    type: [Boolean, String],
    default: false,
  },
  calculatePosition: {
    type: [Boolean, String],
    default: false,
  },
});

const popup = ref(null);

const isActive = ref(toBoolean(props.active));
watch(
  () => props.active,
  (value) => (isActive.value = toBoolean(value)),
);

const { height, bottom, top } = useElementBounding(popup);
const { height: windowHeight } = useWindowSize();

let bottomWatcherStop;
watch(isActive, (value) => {
  // Stop the bottom watcher when the popup is not active
  bottomWatcherStop?.();

  // Schedule the position calculation when the popup is active
  if (value) {
    schedulePositionCalculation();
  }
});

function schedulePositionCalculation() {
  nextTick(() => {
    // Wait for the popup to be rendered
    watchOnce(height, onPopupHasInitialized);
  });
}

function onPopupHasInitialized() {
  if (toBoolean(props.calculatePosition)) {
    calculateInitialPosition();
    bottomWatcherStop = watch(bottom, recalculatePosition);
  }
}

const isLocationTop = ref(false);

/**
 * Calculate the initial position of the popup.
 * This is used to determine if the popup should be displayed on top or bottom of the target element.
 * If the bottom of the popup is greater than the window height, it will change the position to top.
 */
function calculateInitialPosition() {
  if (bottom.value > windowHeight.value) {
    isLocationTop.value = true;
  }
}

/**
 * Recalculate the position of the popup.
 * If the popup is positioned at the top location and the bottom of the popup is less than the window height,
 * it will change the position to bottom.
 */
function recalculatePosition() {
  if (isLocationTop.value && bottom.value + height.value < windowHeight.value) {
    isLocationTop.value = false;
  } else if (!isLocationTop.value && top.value >= windowHeight.value) {
    isLocationTop.value = true;
  }
}

const modifiersClass = computed(() => ({
  "--disabled": toBoolean(props.disabled),
  "--is-permanent": toBoolean(props.permanent),
  "--is-active": isActive.value,
  "--pointer": toBoolean(props.pointer),
  [`--location-${props.location}`]: props.location,
  "--location-top": isLocationTop.value,
  ["--horizontal-align-" + props.horizontalAlign]: props.horizontalAlign,
  [`--pointer-position-${props.pointerPosition}`]: props.pointer,
}));
</script>
<style>
:host {
  display: block;
}
</style>
<style scoped lang="scss">
.sp-popup {
  --popup-width-fallback-value: 18.5rem;
  --popup-min-width: var(--sp-comp-popup-min-width, var(--popup-width-fallback-value));
  --popup-width: var(--sp-comp-popup-width, var(--popup-min-width, var(--popup-width-fallback-value)));
  --popup-radius: var(--sp-comp-menu-border-radius, 0.5rem);
  --popup-bg-color: var(--sp-comp-popup-background-color, #fff);
  --display-pointer: none;
  --pointer-position: 50%; // default is center
  --popup-position: absolute;
  --popup-pointer-size: var(--sp-comp-popup-pointer-size, 1rem);
  --popup-border-width: var(--sp-comp-popup-border-width, 0px); // unit is in that case required for calc() reasons
  --popup-border-style: var(--sp-comp-popup-border-style, solid);
  --popup-border-color: var(--sp-comp-popup-border-color, transparent);
  --popup-border: var(--popup-border-width) var(--popup-border-style) var(--popup-border-color);
  --popup-box-shadow: var(--sp-comp-popup-box-shadow, 0 2px 3px rgba(0, 0, 0, 0.3));

  display: none;
  position: var(--popup-position);
  background-color: var(--popup-bg-color);
  box-shadow: var(--popup-box-shadow);
  margin-top: var(--sp-comp-popup-margin-top, 0);
  min-width: var(--popup-min-width);
  max-width: var(--sp-comp-popup-max-width, 60ch);
  min-height: var(--sp-comp-popup-min-height, 25rem);
  width: var(--popup-width);
  border: var(--popup-border);
  border-radius: var(--popup-radius);
  z-index: var(--sp-comp-popup-z-index, var(--sp-sys-z-index-max, 10));

  // default is horizontal center alignment
  left: 50%;
  transform: translateX(calc(var(--popup-width) / 2 * -1));

  z-index: 10;

  &.--horizontal-align-left,
  &.--location-bottom-left,
  &.--horizontal-align-right,
  &.--location-bottom-right {
    transform: none;
  }

  &.--horizontal-align-left,
  &.--location-bottom-left {
    left: 0;
  }

  &.--horizontal-align-right,
  &.--location-bottom-right {
    right: 0;
    left: auto;
  }

  &.--location-top {
    bottom: calc(var(--popup-pointer-size) + 100%);

    .sp-popup__pointer {
      bottom: 0;
      top: unset;
      transform: rotate(0deg);
    }
  }

  &.--is-permanent {
    --popup-position: relative;
    --popup-width: 100%;
    --display-pointer: none;
    box-shadow: none;
    display: block;
  }

  &.--is-active:not(.--is-permanent) {
    display: block;
  }

  &.--pointer {
    --display-pointer: block;
    margin-top: 0.25rem;
  }

  &.--pointer-position-left {
    --pointer-position: var(--popup-radius);
  }

  &.--pointer-position-right {
    --pointer-position: calc(100% - var(--popup-radius));
  }
}

.sp-popup__pointer {
  --pointer-size: var(--popup-pointer-size);
  --pointer-x: calc(var(--pointer-position) - var(--popup-pointer-size) - var(--popup-border-width));

  position: absolute;
  top: 0;
  width: 100%;
  display: var(--display-pointer);
  z-index: 1;

  &::after {
    position: absolute;
    content: "";
    border-color: transparent transparent var(--popup-bg-color) transparent;
    border-width: calc(var(--pointer-size) / 2);
    border-style: solid;
    width: 0;
    height: 0;
    top: calc(var(--pointer-size) * -1);
    left: var(--pointer-x);

    z-index: -1;
  }

  &::before {
    position: absolute;
    left: var(--pointer-x);
    content: "";
    transform: rotate(-45deg);
    top: calc(var(--pointer-size) / 2 * -1);
    width: var(--pointer-size);
    height: var(--pointer-size);
    box-shadow: var(--popup-box-shadow);
    z-index: -1;
    border: var(--popup-border);
    background-color: var(--popup-bg-color);
  }
}

.sp-popup__content {
  background-color: var(--popup-bg-color);
  border-radius: var(--popup-radius);
  position: relative;
  padding: var(--sp-comp-popup-content-padding, 0);
  z-index: 2;
  overflow: hidden;
}
</style>
