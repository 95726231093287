import { v4 as uuid } from "uuid";
import { Model } from "../../../../shared/models/model";
import { Status } from "./status";

export class Candidate extends Model {
  constructor(attributes = {}) {
    super({ ...attributes, uuid: uuid(), createdAt: Date.now() });
    this.status = undefined;
  }

  set status(value) {
    this._status = new Status(value);
  }

  get status() {
    return this._status;
  }
}
