import { BaseApi } from "../../../../shared/api/base";
import { MenuConfig } from "../models/menu-config";

class MenuConfigApi extends BaseApi {
  constructor() {
    super({ model: MenuConfig });
  }
}

export function useMenuConfigApi() {
  const api = new MenuConfigApi();

  return {
    fetch: (path) => api.get(path),
  };
}
