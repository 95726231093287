/**
 * Convert a string value to a boolean
 * If the value is "true", it returns true, otherwise false
 * If the value is already a boolean, it is returned as is
 *
 * @param {*} value - value to be converted to boolean
 * @returns boolean value
 */
export function toBoolean(value) {
  return value === "true" || value === true;
}

/**
 * Trim a string value and return undefined if the value is empty
 * If the value is not a string, it is returned as is
 *
 * @param {*} value - value to be trimmed
 * @returns value trimmed or undefined if value is empty
 */
export function trimToUndefined(value) {
  if (typeof value === "string") {
    return value.trim() || undefined;
  }
  return value;
}

export function toArray(value) {
  return Array.isArray(value) ? value : [value];
}
