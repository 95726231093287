<template>
  <div ref="root" class="sp-slider">
    <div ref="container" class="sp-slider__container">
      <slot />
    </div>
  </div>
</template>

<script setup>
/**
 * The SpSlider component is a simple slider that allows you to slide through a list of items.
 *
 * @displayName SpSlider
 * @group Custom Elements
 * @component sp-slider
 */
import { useEventListener, watchImmediate, watchOnce } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useExpose } from "../../composables/expose";
import { getSlot } from "../../utils/slots";

const root = ref(null);
const { exposeMethods } = useExpose(root);

exposeMethods({
  previous,
  next,
});

const emit = defineEmits(["update-selected-index", "scrollend"]);

const props = defineProps({
  selectedIndex: {
    type: Number,
    default: 0,
  },
});

const container = ref(null);
const defaultSlot = computed(() => getSlot(container.value));

/**
 * We need to wait until the slot is available before we can compute the items.
 * We use `watchOnce` to watch for changes until the slot is available.
 * Once the slot is available, we start listening for slot changes.
 */
watchOnce(defaultSlot, handleSlotAvailable, { immediate: true });

function handleSlotAvailable(slot) {
  useEventListener(slot, "slotchange", handleSlotChange);
}

/**
 * This function is called whenever the slot changes.
 * It recomputes the items and slides the current item into view.
 */
function handleSlotChange() {
  computeItems();
  slideIntoView();
}

const items = ref([]);
const numberOfItems = computed(() => items.value.length);

/**
 * Computes the items from the default slot.
 * Items are all the elements assigned to the default slot.
 */
function computeItems() {
  items.value = Array.from(defaultSlot.value?.assignedElements?.() ?? []);
}

const selectedIndex = ref(props.selectedIndex);
const scrollHasEnded = ref(false);

watch(
  () => props.selectedIndex,
  (index) => (selectedIndex.value = index),
);

/**
 * Watch for changes in the selected index.
 * Whenever the selected index changes, we slide the current item into view.
 * We also emit an event to notify the parent component of the change.
 *
 * @param {Number} index - The selected index.
 */
watch(selectedIndex, (index) => {
  slideIntoView();
  emit("update-selected-index", index);
});

/**
 * Moves to the previous item.
 * If the current index is already at the first item, it does nothing.
 */
async function previous() {
  return applyMovement(-1);
}

/**
 * Moves to the next item.
 * If the current index is already at the last item, it does nothing.
 */
async function next() {
  return applyMovement(1);
}

async function applyMovement(move) {
  const index = selectedIndex.value + move;

  scrollHasEnded.value = false;

  const nextIndex = Math.max(0, Math.min(index, numberOfItems.value - 1));

  if (nextIndex === selectedIndex.value) {
    return;
  }

  selectedIndex.value = nextIndex;

  return new Promise((resolve) => {
    const stop = watchImmediate(scrollHasEnded, (value) => {
      if (!value) {
        return;
      }
      stop();
      resolve();
    });
  });
}

/**
 * Slides the item at the current index into view.
 * If the item is not available, it does nothing.
 */
function slideIntoView() {
  const slide = items.value?.[selectedIndex.value];

  if (!slide) {
    return;
  }

  scrollHasEnded.value = false;
  slide.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
}
</script>

<style>
:host {
  --slides-per-page: 1;
  --aspect-ratio: 0;
  --slide-gap: var(--sp-comp-slider-slide-gab, 1rem);
  --scroll-hint: var(--sp-comp-slider-scroll-hint, 0px);
  display: flex;
  box-sizing: border-box;
}
</style>

<style scoped lang="scss">
.sp-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.sp-slider__container {
  --slide-size: calc((100% - (var(--slides-per-page) - 1) * var(--slide-gap)) / var(--slides-per-page));
  box-sizing: border-box;
  grid-area: slides;
  display: grid;
  height: 100%;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: var(--slide-size);
  grid-auto-rows: 100%;
  column-gap: var(--slide-gap);
  scroll-snap-type: x mandatory;
  scroll-padding-inline: var(--scroll-hint);
  padding-inline: var(--scroll-hint);
  overflow-y: hidden;
  place-items: center;
  overflow: hidden;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  aspect-ratio: calc(var(--aspect-ratio) * var(--slides-per-page));
  transition: aspect-ratio 5s ease-in-out;
}
</style>
