<template>
  <div class="sp-ai-generator-quill-app">
    <sp-animated-ellipsis v-if="loading" size="var(--sp-sys-spacing-3)"></sp-animated-ellipsis>

    <template v-else-if="hasMenuConfigOptions">
      <sp-menu v-if="hasManyConfigOptions" location="bottom-right">
        <ActivatorButton slot="activator" :text="activatorText" part="activator" />
        <sp-list :items="menuConfig" item-title="text" @select-item="handleSelectMenuItem"></sp-list>
      </sp-menu>

      <ActivatorButton v-else :text="activatorText" @click="handleGenerateContentClick" />
    </template>

    <sp-tooltip v-else-if="error" class="error-box" pointer-position="right" horizontal-align="right">
      <sp-icon
        class="tooltip-activator"
        name="error-page"
        fill-color="var(--sp-sys-color-danger)"
        size="var(--sp-icon-size-small)"
      ></sp-icon>
      <span slot="content">{{ error }} Error Lorem ipsum dolor sit amet</span>
    </sp-tooltip>

    <MenuActionPromise v-slot="{ resolve, args: [{ component, ...componentProps }] }">
      <component :is="component" v-bind="componentProps" open @update-model-value="resolve" @close="resolve" />
    </MenuActionPromise>
  </div>
</template>

<script setup>
/**
 * The SpAiGeneratorQuillApp component acts as a bridge between the Quill editor and the SparkleAI generator.
 * It allows the user to generate content using the SparkleAI API and update the Quill editor with the generated content.
 *
 * If there are multiple menu options, it displays a dropdown menu to select the content type.
 * Otherwise, it displays a button to generate content directly.
 *
 * If the menu configuration could not be fetched, it displays an error message.
 *
 * The menu configuration is fetched from the provided URL and must be an array of objects with the following structure:
 * @typedef {Array<{ text: String, component: String, ...extra: any }>}
 * @property {String} text - The text to display in the menu.
 * @property {String} component - The component to render in the modal. Default is `sp-ai-generator-app`.
 * @property {any} extra - Extra properties to pass to the component.
 *
 * A component is a Vue component that will be rendered in the modal to generate content.
 * The extra properties are passed as props to the component.
 * The component must emit an `update-model-value` event with the generated content.
 * The component must also emit a `close` event when the modal is closed.
 *
 * @see {@link sp-ai-generator-app}
 */
import { createTemplatePromise, watchImmediate } from "@vueuse/core";
import { computed, nextTick, ref } from "vue";
import { useMenuConfigApi } from "./api/menu-config";
import ActivatorButton from "./components/ActivatorButton.ce.vue";

const props = defineProps({
  /**
   * The Quill editor instance.
   * @type {Object}
   * @default undefined
   */
  quill: {
    type: Object,
    default: undefined,
  },
  /**
   * The URL to fetch the menu configuration.
   * @type {String}
   * @default undefined
   */
  menuConfigUrl: {
    type: String,
    default: undefined,
  },
  /**
   * The activator button text which appears on the toolbar.
   * @type {String}
   */
  activatorText: {
    type: String,
    default: "Generate Content",
  },
});

/**
 * The promise to handle the menu action.
 * It resolves with the generated content or simply closes the modal.
 *
 * @type {Promise}
 * @see {@link createTemplatePromise}
 */
const MenuActionPromise = createTemplatePromise();

const loading = ref(false);
const error = ref(null);

const api = useMenuConfigApi();

/**
 * The menu configuration.
 * @type {Array<{ text: String, component: String, ...extra: any }>}
 */
const menuConfig = ref(null);
const hasMenuConfigOptions = computed(() => menuConfig.value?.length > 0);
const hasManyConfigOptions = computed(() => menuConfig.value?.length > 1);

/**
 * When the menu configuration URL changes, fetch the menu configuration again.
 *
 * @param {String} url - The URL to fetch the menu configuration from.
 * @see {@link useMenuConfigApi}
 */
watchImmediate(() => props.menuConfigUrl, fetchMenuConfig);

/**
 * Fetches the menu configuration from the provided URL.
 * If the menu config url is not provided, it does nothing.
 *
 * @param {String} url - The URL to fetch the menu configuration from.
 */
async function fetchMenuConfig(url) {
  if (!props.menuConfigUrl) {
    return;
  }

  try {
    error.value = null;
    loading.value = true;
    menuConfig.value = await api.fetch(url);
  } catch (err) {
    error.value = "Sparkle AI not loaded.";
    console.error(err);
  } finally {
    loading.value = null;
  }
}

/**
 * Handles the click event to generate content.
 * It opens the generator modal with the first menu item.
 */
function handleGenerateContentClick() {
  openGenerator(menuConfig.value?.[0]);
}

/**
 * Handles the selection of a menu item.
 * It opens the generator modal with the selected menu item.
 *
 * @param {Object} item - The selected menu item.
 */
function handleSelectMenuItem({ detail }) {
  const [config] = detail;
  openGenerator(config);
}

/**
 * Opens the generator modal to generate content.
 * Once the modal is closed, it updates the Quill editor with the generated content.
 *
 * @param {Object} config
 */
async function openGenerator(config) {
  const modelValue = props.quill.getText();
  const component = config?.component ?? "sp-ai-generator-app";
  const { detail } = await MenuActionPromise.start({ ...config, component, modelValue });

  updateModelValue(detail?.[0]);
}

/**
 * Updates the Quill editor with the given value.
 * If the value is empty, it does nothing.
 * This might happen when the user closes the modal without generating content or decided to keep the original content.
 *
 * @param {String} value - The value to update the Quill editor with.
 */
function updateModelValue(value) {
  if (!value) {
    return;
  }
  nextTick(() => props.quill.setText(value));
}
</script>

<style>
:host {
  display: inline-flex;
}
</style>

<style lang="scss" scoped>
.sp-ai-generator-quill-app {
  display: flex;
  position: relative;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.error-box {
  margin-left: 0.5ch;
  display: flex;
  align-items: center;
  color: var(--sp-sys-color-danger);
  font-size: small;
}

.error-box sp-icon {
  margin-right: 0.25ch;
}

sp-animated-ellipsis {
  --height: var(--sp-ref-spacing-2);
}
</style>
