import { computed } from "vue";
import { useModifiers } from "./classes";

export function useVariant(props) {
  const { modifiers } = useModifiers(props, "variant");
  const variantModifiers = computed(() => modifiers.value);

  return {
    variantModifiers,
  };
}
