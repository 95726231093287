<template>
  <sp-button :size="size" color="primary" tag="a">
    <slot />
  </sp-button>
</template>

<script setup>
import { ref, onMounted } from "vue";

defineProps({
  noop: {
    type: String,
    default: "noop",
  },
});

const size = ref("small");
function setSize() {
  const cpSize = getComputedStyle(document.documentElement).getPropertyValue("--sp-ce-broadcast-message-action-size");
  const validatorSizes = ["small", "medium", "large", "default"];

  if (validatorSizes.includes(cpSize)) {
    size.value = cpSize;
  }
}

onMounted(() => {
  setSize();
});
</script>
