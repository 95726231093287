<template>
  <div class="sp-toolbar" :class="classModifiers">
    <div ref="content">
      <slot ref="slot" />
    </div>
  </div>
</template>

<script setup>
import { useWindowScroll } from "@vueuse/core";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  /**
   * Whether the toolbar should be sticky or not.
   *
   * @type {Boolean}
   * @default false
   */
  sticky: {
    type: [Boolean, String],
    default: false,
  },

  /**
   * Whether the toolbar should be sticky on scroll or not.
   * Just like the `sticky` prop, but this one will be sticky on scroll.
   * If `sticky` is defined, this prop will be ignored.
   *
   * @type {Boolean}
   * @default false
   */
  stickyOnScroll: {
    type: [Boolean, String],
    default: false,
  },
});

const { y } = useWindowScroll();

const content = ref(null);
const slotContents = computed(() => {
  if (content.value?.firstElementChild?.tagName === "SLOT") {
    return content.value.firstElementChild.assignedElements();
  }
  return Array.from(content.value?.children ?? []);
});

const slotContent = computed(() => (slotContents.value ?? []).at(0));

const slotContentOffsetTop = ref(0);

const stickToTop = ref(false);

/**
 * Watch the `stickToTop` ref and update the `slotContent.stuckToTop` property.
 * This will allow the slot content to know if it's stuck to the top or not.
 */
watch(stickToTop, (isSticky) => {
  if (!slotContent.value) {
    return;
  }
  nextTick(() => (slotContent.value.stuckToTop = isSticky));
});

const classModifiers = computed(() => ({
  "--is-sticky": toBoolean(props.sticky) || stickToTop.value,
}));

const unwatch = ref(null);

function initializePositionWatcher() {
  if (toBoolean(props.stickyOnScroll)) {
    const callback = () => {
      // If the toolbar is not sticky, calculate the offset top.
      // This is necessary to do on demand because the dom might be mutated which might change the offset top.
      if (!stickToTop.value) {
        slotContentOffsetTop.value = calculateOffsetTop();
      }

      stickToTop.value = window.scrollY > slotContentOffsetTop.value;
    };

    unwatch.value = watch(y, () => callback());
    // Call the callback once to initialize the scroll position.
    callback();
  } else {
    unwatch.value?.();
  }
}

function calculateOffsetTop() {
  const boundingClientRect = slotContent.value?.getBoundingClientRect();

  if (boundingClientRect) {
    return boundingClientRect.top + window.scrollY;
  }
}

watch(
  () => props.stickyOnScroll,
  () => initializePositionWatcher(),
);

onMounted(() => {
  nextTick(() => initializePositionWatcher());
});
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-toolbar {
  &.--is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
}
</style>
