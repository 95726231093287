import { props as textFieldProps } from "../SpTextField/props.js";

export const props = Object.freeze({
  ...textFieldProps,
  /**
   * Clears the input field when the clear button is clicked.
   * This will render a clear button on the right side of the input field.
   * This will only be visible when the input field is not empty.
   *
   * @type {boolean}
   * @default false
   */
  clearable: {
    type: [Boolean, String],
    default: false,
  },
  placeholder: {
    ...textFieldProps.placeholder,
    default: "Search",
  },
});
