<template>
  <InsurancePlanFilter ref="filter" class="filter" :class="breakpointModifierClass" close-on-select inline>
    <template #activator>
      <sp-button variant="outlined" class="activator">
        <sp-icon slot="prepend" name="insurance-card-filled"></sp-icon>
        {{ title }}
        <sp-icon slot="append" name="popup-arrow-filled"></sp-icon>
      </sp-button>
    </template>
  </InsurancePlanFilter>
</template>

<script setup>
import { computed, ref } from "vue";
import { useBreakpoints } from "../../../composables/breakpoints";
import InsurancePlanFilter from "../../shared/components/InsurancePlanFilter.ce.vue";
defineProps({
  title: {
    type: String,
    default: "Check your insurance",
  },
});
const filter = ref(null);
const { isGtSmScreen } = useBreakpoints(filter);

const breakpointModifierClass = computed(() => ({
  "--bp-lt-sm": !isGtSmScreen.value,
}));
</script>

<style scoped lang="scss">
.activator {
  --sp-ce-button-border-radius: 12px;
  --sp-ce-button-outlined-border-style: dashed;
  --sp-ce-button-outlined-border-color: #b2d4d7;
  --sp-ce-button-prepend-icon-size: 20px;
  --sp-ce-button-append-icon-size: 20px;
  --font-family: var(--sp-sys-font-family-medium);

  &:hover {
    --sp-ce-button-outlined-border-style: solid;
  }
}
.filter {
  &.--bp-lt-sm {
    --filter-width: 100%;
  }
}
</style>
