<template>
  <div class="sp-alert" :class="classModifiers">
    <div ref="prependSlot" class="sp-alert__prepend">
      <slot name="prepend"></slot>
    </div>
    <div class="sp-alert-content">
      <slot></slot>
    </div>
    <div ref="appendSlot" class="sp-alert__append">
      <slot name="append"></slot>
    </div>
    <div ref="closeSlot" class="sp-alert__close">
      <slot name="close"></slot>
    </div>
  </div>
</template>
<script setup>
import { useMediaQuery } from "@vueuse/core";
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: undefined,
    validator: (value) => ["success", "info", "warning", "error"].includes(value),
  },
  variant: {
    type: String,
    default: undefined,
    validator: (value) => ["elevated", "flat", "outlined", "text"].includes(value),
  },
  appendAlign: {
    type: String,
    default: undefined,
    validator: (value) => ["start", "center", "end"].includes(value),
  },
  compact: {
    type: Boolean,
    default: false,
  },
});

const mdBreakpoint = computed(() =>
  window.getComputedStyle(document.documentElement).getPropertyValue("--sp-ref-breakpoint-md"),
);

const isGtMdScreen = useMediaQuery(`(min-width: ${mdBreakpoint.value})`);

const classModifiers = computed(() => ({
  [`--variant-${props.variant}`]: props.variant,
  [`--type-${props.type}`]: props.type,
  [`--append-align-${props.appendAlign}`]: props.appendAlign,
  "--compact": props.compact,
  "--bp-min-width-sm": isGtMdScreen.value,
}));
</script>
<style>
:host {
  --alert-background-color: var(--sp-comp-alert-background-color, var(--sp-sys-color-surface, #ffffff));
  --alert-text-color: var(--sp-comp-alert-text-color, var(--sp-sys-color-on-surface, #1e293b));
  --alert-info-background-color: var(--sp-comp-alert-info-background-color, #eef4f5);
  --alert-info-text-color: var(--sp-comp-alert-info-text-color, var(--sp-sys-color-on-surface, #1e293b));
  --alert-info-link-color: var(--sp-comp-alert-info-link-color, var(--sp-sys-color-link, #007078));
  --alert-warning-background-color: var(--sp-comp-alert-warning-background-color, #fff8e8);
  --alert-warning-text-color: var(--sp-comp-alert-warning-text-color, var(--sp-sys-color-on-surface, #1e293b));
  --alert-warning-link-color: var(--sp-comp-alert-warning-link-color, var(--sp-sys-color-link, #007078));
  --alert-error-background-color: var(--sp-comp-alert-error-background-color, #f1d7d9);
  --alert-error-text-color: var(--sp-comp-alert-error-text-color, var(--sp-sys-color-on-surface, #1e293b));
  --alert-error-link-color: var(--sp-comp-alert-error-link-color, var(--sp-sys-color-link, #007078));
  --alert-success-background-color: var(--sp-comp-alert-success-background-color, rgba(204, 236, 212, 0.5));
  --alert-success-text-color: var(--sp-comp-alert-success-text-color, var(--sp-sys-color-on-surface, #1e293b));
  --alert-success-link-color: var(--sp-comp-alert-success-link-color, var(--sp-sys-color-link, #007078));
  --alert-border-radius: var(--sp-comp-alert-border-radius, 0.3125rem);
  --alert-content-line-height: var(--sp-comp-alert-content-line-height, 1.5);
  --alert-display: var(--sp-comp-alert-display, grid);
  --alert-prepend-icon-size: var(--sp-comp-alert-prepend-icon-size, 55px);
  --alert-append-icon-size: var(--sp-comp-alert-append-icon-size, 22px);
  --alert-append-icon-fill: var(--sp-comp-alert-append-icon-fill, var(--sp-sys-color-link));
  --alert-column-gap: var(--sp-comp-alert-column-gap, var(--sp-ref-spacing-4));

  display: var(--alert-display);
}
</style>
<style lang="scss" scoped>
@import "sp-alert-content";

.sp-alert {
  --alert-append-align: var(--sp-comp-alert-append-align, flex-start);
  --alert-append-column-gap: 0;
  --alert-padding: var(--sp-comp-alert-padding, calc(var(--sp-ref-spacing-6) * 2));
  --alert-content-font-family: var(--sp-comp-alert-content-font-family, var(--sp-sys-font-family-normal));
  --alert-content-font-weight: var(--sp-comp-alert-content-font-weight, var(--sp-sys-font-weight-normal));
  --alert-content-font-size: var(--sp-comp-alert-content-font-size, var(--sp-ref-font-size-medium));

  display: var(--alert-display);
  padding: var(--alert-padding);
  position: relative;
  background-color: var(--alert-background-color);
  color: var(--alert-text-color);
  box-shadow: var(--alert-box-shadow, none);
  border-radius: var(--alert-border-radius);
  grid-template-areas: "prepend content append close";
  grid-template-columns: max-content auto max-content max-content;
  row-gap: var(--alert-column-gap);

  @each $type in ("info", "warning", "error", "success") {
    &.--type-#{$type} {
      --alert-background-color: var(--alert-#{$type}-background-color);
      --alert-text-color: var(--alert-#{$type}-text-color);
    }
  }

  &.--variant-elevated {
    --alert-box-shadow: var(--sp-comp-alert-elevated-box-shadow, 0 2px 10px 0 rgba(0, 0, 0, 0.25));
  }

  &.--variant-text {
    --alert-background-color: transparent;
    --alert-padding: 0;
    --alert-border-radius: 0;
    --alert-content-line-height: 1.2;
    --alert-display: inline-grid;
    --alert-prepend-icon-size: 1em;
    --alert-append-icon-size: 1em;
  }

  &.--append-align-center {
    --alert-append-align: center;
  }

  &.--append-align-end {
    --alert-append-align: flex-end;
  }

  &.--compact {
    --alert-padding: var(--sp-comp-alert-compact-padding, var(--sp-ref-spacing-6));
    --alert-prepend-icon-offset: 0;
    --alert-column-gap: 0.5ch;
    --alert-content-font-family: var(--sp-comp-alert-compact-content-font-family, var(--sp-sys-font-family-medium));
    --alert-content-font-weight: var(--sp-comp-alert-compact-content-font-weight, var(--sp-sys-font-weight-medium));
    --alert-content-font-size: var(--sp-comp-alert-compact-content-font-size, 0.9375rem);
    --alert-append-align: center;
    --alert-border-radius: var(--sp-comp-alert-compact-border-radius, 0.75rem);
  }
}

.sp-alert__prepend {
  align-self: flex-start;
  align-items: center;
  display: flex;
  grid-area: prepend;

  & ::slotted(sp-icon) {
    --font-size: var(--alert-prepend-icon-font-size, 1rem);
    margin-inline-end: var(--alert-column-gap);
    --size: var(--alert-prepend-icon-size);
    margin-left: var(--alert-prepend-icon-offset, -8px);
  }
}

.sp-alert__append {
  align-self: var(--alert-append-align);
  align-items: center;
  display: flex;
  grid-area: append;

  & ::slotted([slot="append"] > *) {
    margin-inline-start: var(--alert-append-column-gap);
  }

  & ::slotted(sp-icon) {
    --size: var(--alert-append-icon-size);
    --fill: var(--alert-append-icon-fill);
  }
}

.sp-alert__close {
  align-self: flex-start;
  align-items: center;
  display: flex;
  grid-area: close;
  cursor: pointer;

  & ::slotted(sp-icon) {
    margin-inline-start: var(--alert-column-gap);
    opacity: 0.3;
    margin-right: -8px;
  }
}
</style>
