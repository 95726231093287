<template>
  <div class="sp-switch" :class="classModifiers">
    <div class="sp-switch__selection-control">
      <div class="sp-switch__control-wrapper">
        <div class="sp-switch__control-input">
          <input id="switch" v-model="checked" type="checkbox" :name="name" :disabled="disabled" />
          <div class="sp-switch__track">
            <div class="sp-switch__thumb"></div>
          </div>
        </div>
      </div>
      <label for="switch">{{ label }}</label>
    </div>
    <div v-if="helpText" class="sp-switch__help-text">{{ helpText }}</div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const emit = defineEmits("update-ckecked");

const props = defineProps({
  checked: {
    type: [Boolean, String],
    default: false,
  },
  label: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  helpText: {
    type: String,
    default: undefined,
  },
});

const checked = ref(toBoolean(props.checked));
watch(checked, (value) => emit("update-checked", value));
watch(
  () => props.checked,
  (value) => (checked.value = toBoolean(value)),
);

const classModifiers = computed(() => ({
  "--checked": checked.value,
  "--disabled": toBoolean(props.disabled),
}));
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-switch {
  --track-width: var(--sp-comp-switch-track-min-width, 36px);
  --track-bg-color-checked: var(--sp-comp-switch-track-bg-color-checked, var(--sp-sys-color-primary, #225ea6));

  display: grid;
  position: relative;
  grid-template-areas: "control" "message";
  grid-template-rows: auto auto;

  &.--disabled {
    .sp-switch__track,
    label {
      opacity: 0.3;
    }
  }
}

.sp-switch__selection-control {
  display: grid;
  grid-template-columns: auto 1fr;
}

.sp-switch__control-wrapper {
  display: flex;
  min-width: var(--sp-comp-switch-control-wrapper-min-width, var(--track-width));
  align-items: center;
  position: relative;
  justify-content: center;
  flex: none;
}

.sp-switch__track {
  --track-bg-color: var(--sp-comp-switch-track-background-color, #afafaf);
  --track-height: var(--sp-comp-switch-track-height, 16px);
  --track-outline-width: var(--sp-comp-switch-track-outline-width, 1px);

  min-width: var(--track-width);
  height: var(--track-height);
  background-color: var(--track-bg-color);
  border-radius: calc(var(--track-height) / 2);
  outline: var(--track-outline-width) solid var(--sp-comp-track-outline-color, var(--track-bg-color));
  position: absolute;
  margin-left: var(--track-outline-width);
}

.sp-switch .sp-switch__control-input {
  border-radius: 50%;
  position: absolute;
}

.sp-switch__control-input {
  min-width: var(--sp-comp-switch-control-input-min-width, var(--track-width));
  position: absolute;
  height: 50%;
  align-items: center;
  display: flex;
  flex: none;
  justify-content: center;
}

.sp-switch__thumb {
  --thumb-size: var(--sp-comp-switch-thumb-size, var(--track-height));

  background-color: var(--sp-comp-switch-thumb-background-color, #fff);
  border-radius: calc(var(--thumb-size) / 2);
  color: var(--sp-comp-switch-thumb-color, #000);
  display: flex;
  height: var(--thumb-size);
  width: var(--thumb-size);
  justify-content: center;
  pointer-events: none;
  position: absolute;
  left: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  transform: translateX(0);
}

label {
  padding-left: var(--sp-com-switch-label-gap, 1ch);
}

input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  margin: 0;
  padding: 0;
  z-index: 3;
}

input:checked + .sp-switch__track .sp-switch__thumb {
  transform: translateX(calc(var(--track-width) - var(--thumb-size)));
}

input:checked + .sp-switch__track {
  --track-bg-color: var(--track-bg-color-checked);
}

input:disabled {
  cursor: not-allowed;
}

input:disabled + .sp-switch__track {
  background-color: var(--track-bg-color);
}

#switch {
  opacity: 0;
  border-radius: 50%;
}

.sp-switch__help-text {
  --help-text-font-size: var(--sp-comp-switch-help-text-size, 14px);
  --help-text-color: var(--sp-comp-switch-help-text-color, #4a4a4a);
  --help-text-opacity: var(--sp-comp-switch-help-text-opacity, 1);
  --help-text-padding-left: var(--sp-comp-switch-help-text-padding-left, 0px);

  font-size: var(--help-text-font-size);
  color: var(--help-text-color);
  opacity: var(--help-text-opacity);
  padding-left: var(--help-text-padding-left);
}
</style>
