import { computed } from "vue";
import { toBoolean } from "../../utils/props";
import { isObjectLike } from "../../utils/types";

export function useSpList(props) {
  const isReadonly = computed(() => toBoolean(props.readonly));

  /**
   * Map of indexes of list items that are not headers.
   * It is used to determine the index of a list item in the list of list items that are not headers.
   *
   * @type {import("vue").ComputedRef<number[]>}
   */
  const listItemIndexes = computed(() =>
    (props.items ?? [])
      .map((item, index) => (!isHeader(item) ? index : undefined))
      .filter((index) => index !== undefined),
  );

  /**
   * Returns the index of a list item in the list of list items that are not headers.
   *
   * @param {*} index - The index of the list item in the list of all list items.
   * @returns {number} The index of the list item in the list of list items that are not headers.
   */
  function indexOfListItem(index) {
    return listItemIndexes.value?.indexOf(index);
  }

  /**
   * Returns the list item at the specified index.
   * The index is the index of the list item in the list of list items that are not headers.
   *
   * @param {number} index - The index of the list item in the list of list items that are not headers.
   * @returns {*} The list item at the specified index.
   * @type {import("vue").ComputedRef<*>}
   */
  function listItemOfIndex(index) {
    return props.items[listItemIndexes.value[index]];
  }

  function isHeader(item) {
    return isObjectLike(item) && "header" in item;
  }

  function isSelectable(item) {
    return isReadonly.value !== true && getItemProps(item)?.selectable !== false;
  }

  function getItemTitle(item) {
    return getItemProperty(item, props.itemTitle);
  }

  function getItemSubtitle(item) {
    if (!isObjectLike(item)) {
      return;
    }
    return item[props.itemSubtitle];
  }

  function getItemValue(item) {
    return getItemProperty(item, props.itemValue);
  }

  function getItemProps(item) {
    if (!isObjectLike(item)) {
      return {};
    }
    if (toBoolean(props.itemProps)) {
      return item;
    }
    return item[props.itemProps] ?? {};
  }

  function getItemProperty(item, property) {
    return !isObjectLike(item) ? item : item[property];
  }

  return {
    getItemTitle,
    getItemSubtitle,
    getItemValue,
    getItemProps,
    listItemIndexes,
    indexOfListItem,
    listItemOfIndex,
    isHeader,
    isSelectable,
    isReadonly,
  };
}
