<template>
  <div class="sp-card-subtitle">
    <slot />
  </div>
</template>

<script setup></script>

<style>
:host {
  display: block;
}
</style>
