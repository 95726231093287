<template>
  <svg viewbox="0 0 120 24">
    <circle v-for="i in numberOfCircles" :key="i" :r="radius" :cx="`${calculateCX(i)}`" :cy="radius">
      <animate
        attributeName="opacity"
        :dur="duration"
        values="0.2;1;0.2"
        keyTimes="0; 0.2; 1"
        repeatCount="indefinite"
        :begin="`0.${i}`"
      />
    </circle>
  </svg>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  size: {
    type: String,
    default: "small",
  },
  color: {
    type: String,
    default: "currentColor",
  },
});

const sizes = {
  "x-small": 2,
  small: 4,
  normal: 8,
  large: 12,
  "x-large": 16,
};

const duration = "1s";
const numberOfCircles = 3;

const radius = computed(() => sizes[props.size] ?? sizes.normal);
const distance = computed(() => radius.value * 4);
const diameter = computed(() => radius.value * 2);
const height = computed(() => `${diameter.value}px`);

function calculateCX(i) {
  const distanceMultiplier = i - 1;
  return radius.value + distance.value * distanceMultiplier;
}
</script>

<style>
:host {
  display: flex;
}
</style>

<style scoped>
svg {
  height: v-bind(height);
  width: auto;
  display: block;
}

circle {
  fill: v-bind(color);
  opacity: 0;
}
</style>
