<template>
  <InsurancePlanFilterOutlined v-if="model.isUndefined" />
  <sp-alert
    v-else-if="!model.isSelfPay"
    ref="alert"
    class="insurance-alert"
    :class="breakpointModifierClass"
    :variant="variant"
    :type="model.type"
    compact
  >
    <CompactLayoutContent :model="model" />
  </sp-alert>
</template>

<script setup>
import { computed, ref } from "vue";
import { useBreakpoints } from "../../../composables/breakpoints";
import CompactLayoutContent from "./CompactLayoutContent.ce.vue";
import InsurancePlanFilterOutlined from "./InsurancePlanFilterOutlined.ce.vue";

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
});

const variant = computed(() => (props.model.isUndefined.value ? "flat" : undefined));
const alert = ref(null);
const { isGtSmScreen } = useBreakpoints(alert);

const breakpointModifierClass = computed(() => ({
  "--bp-lt-sm": !isGtSmScreen.value,
}));
</script>

<style lang="scss" scoped>
.insurance-alert {
  margin-bottom: 0;
  --alert-append-icon-size: 22px;
  --alert-append-icon-fill: var(--sp-sys-color-link);
  --alert-append-icon-cursor: pointer;
  --alert-prepend-icon-size: 26px;
  --alert-display: inline-grid;
  &.--bp-lt-sm {
    --alert-display: grid;
  }
}
</style>
