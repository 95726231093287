import { computed, reactive, ref } from "vue";
import { useGeneratorApi } from "../api/generator.js";
import { Candidate } from "../models/candidate.js";
import { Status } from "../models/status.js";

export const useGeneratorStore = (url, sendOriginalContent) => {
  const api = useGeneratorApi(url);

  const candidates = ref([]);
  const numberOfCandidates = computed(() => candidates.value.length);
  const hasCandidates = computed(() => numberOfCandidates.value > 0);
  const hasManyCandidates = computed(() => numberOfCandidates.value > 1);
  const selectedCandidate = ref(null);

  /**
   * Generates a candidate from the original content.
   *
   * If sendOriginalContent is true, it sends the original content to the server.
   * Otherwise, the server generates the candidate based on the field parameters.
   *
   * @param {Object} originalContent The original content to generate the candidate from.
   * @returns {Promise<void>}
   */
  async function generateContent(input) {
    // Create a new candidate
    const candidate = reactive(new Candidate({ label: `Option ${nextOptionLabelSuffix()}` }));

    // Add the candidate to the list to make it available for the UI
    candidates.value.push(candidate);

    // Select the candidate to make it the prominent one in the UI
    selectCandidate(candidate);

    candidate.status = Status.LOADING;

    const data = sendOriginalContent ? { input } : undefined;
    const generatedContent = await api.generateContent(data);

    if (!generatedContent?.text) {
      throw new Error("Error #10704: The server did not return a valid response.");
    }

    candidate.content = generatedContent.text;
    candidate.status = Status.SUCCESS;
  }

  /**
   * Removes a candidate from the list of candidates.
   * Also, select the last candidate in the list.
   */
  function removeCandidate(candidate) {
    candidates.value = candidates.value.filter(({ uuid }) => uuid !== candidate.uuid);
    selectCandidate(candidates.value.at(-1));
  }

  /**
   * Removes all candidates with an error status.
   * This is useful when the user wants to retry generating the content.
   */
  function removeErrorCandidates() {
    candidates.value = candidates.value.filter(({ status }) => !status.isError);
  }

  /**
   * Returns the next suffix for the option label.
   * If the number of candidates is greater than the number of characters in the alphabet, it returns the number of candidates.
   * Otherwise, it returns the next character in the alphabet.
   *
   * @returns {string}
   */
  function nextOptionLabelSuffix() {
    const numberOfCharactersInAlphabet = 26;
    if (numberOfCandidates.value >= numberOfCharactersInAlphabet) {
      return numberOfCandidates.value.toString();
    }
    const nextCharInAlphabet = String.fromCharCode("A".charCodeAt(0) + numberOfCandidates.value);
    return nextCharInAlphabet;
  }

  const indexOfSelectedCandidate = computed(() => {
    if (!selectedCandidate.value) {
      return -1;
    }

    return candidates.value.findIndex(({ uuid }) => uuid === selectedCandidate.value.uuid);
  });

  function isSelectedCandidate(candidate) {
    return selectedCandidate.value?.uuid === candidate.uuid;
  }

  const hasPreviousCandidate = computed(() => indexOfSelectedCandidate.value > 0);
  const hasNextCandidate = computed(() => indexOfSelectedCandidate.value < numberOfCandidates.value - 1);

  function selectPreviousCandidate() {
    const prevIndex = indexOfSelectedCandidate.value - 1;
    selectedCandidate.value = candidates.value[prevIndex];
  }

  function selectNextCandidate() {
    const nextIndex = indexOfSelectedCandidate.value + 1;
    selectedCandidate.value = candidates.value[nextIndex];
  }

  function selectCandidate(candidate) {
    selectedCandidate.value = candidate;
  }

  function clear() {
    candidates.value = [];
  }

  return {
    // State
    candidates,
    numberOfCandidates,
    hasCandidates,
    hasManyCandidates,
    hasPreviousCandidate,
    hasNextCandidate,
    selectedCandidate,
    indexOfSelectedCandidate,

    // Actions
    clear,
    generateContent,
    selectCandidate,
    selectPreviousCandidate,
    selectNextCandidate,
    isSelectedCandidate,
    removeCandidate,
    removeErrorCandidates,
  };
};
