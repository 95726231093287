<template>
  <div>
    <div class="sp-linear-progress-bar" :class="modifierClassnames" :style="style"></div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
const emit = defineEmits(["input"]);

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
  height: {
    type: String,
    default: undefined,
  },
  color: {
    type: String,
    default: undefined,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  roundedBar: {
    type: Boolean,
    default: false,
  },
  indeterminate: {
    type: Boolean,
    default: false,
  },
  animationTime: {
    type: String,
    default: "0.5s",
  },
});

const model = ref(props.value);
watch(model, () => emit("input", model.value));
watch(
  () => props.value,
  (value) => {
    model.value = value;
  },
);

const style = computed(() => ({
  "--height": props.height,
  "--color": props.color,
  "--value": `${model.value}%`,
  "--animation-time": props.animationTime,
}));

const modifierClassnames = computed(() => ({
  "--rounded": props.rounded,
  "--rounded-bar": props.roundedBar,
  "--indeterminate": props.indeterminate,
}));
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-linear-progress-bar {
  --height: var(--sp-comp-linear-progress-bar-height, 0.2rem);
  --color: var(--sp-comp-linear-progress-bar-color, #007bff);
  --animation-time: var(--sp-comp-linear-progress-bar-animation-time, 0.5s);
  --progress-border-radius: calc(var(--height) / 2);
  height: var(--height);
  background-color: #ddd;
  position: relative;
  overflow: hidden;

  &.--indeterminate {
    &::after {
      left: 0;
      animation: load 1.5s infinite linear;
    }
  }
  &.--rounded-bar,
  &.--rounded::after {
    border-radius: var(--progress-border-radius);
  }
}

.sp-linear-progress-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--color);
  transform: translateX(var(--value));
  transition: transform var(--animation-time) ease-in-out;
}

@keyframes load {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
