import axios from "axios";

let icons = null;
let fetchPromise = null;

const fetchIcons = async (host) => {
  if (icons) {
    return icons;
  }

  if (!fetchPromise) {
    let url = "/assets/icons.json";
    if (host) {
      url = `${host}${url}`;
    }

    fetchPromise = axios
      .get(url)
      .then((response) => {
        icons = response.data;
        fetchPromise = null; // Reset the fetchPromise after completion
        return icons;
      })
      .catch((err) => {
        fetchPromise = null; // Reset the fetchPromise if an error occurs
        throw err;
      });
  }
  return fetchPromise;
};

export { fetchIcons };
