import axios from "axios";
import App from "./App.ce.vue";

export const setup = (props) => {
  // Set the default embed host. This was required to make the app work in embed mode.
  axios.defaults.embedHost = props.embedHost ?? document.location.origin;

  return Object.freeze({
    component: App,
  });
};
