<template>
  <div class="sp-quantum-select-card">
    <div class="sp-quantum-select-card__header">
      <sp-search-box
        v-if="!hideSearchBar"
        v-bind="$props"
        :value="search"
        @text-input="({ detail }) => $emit('update-search', ...detail)"
        @keydown.space.stop
        @keydown.enter.stop
      />
    </div>

    <div class="sp-quantum-select-card__content">
      <sp-list
        v-if="hasItems"
        v-bind="$props"
        :items="items"
        selected-prepend-icon="check-filled"
        @select-item="onItemSelect"
        @update-selected="onUpdateSelected"
      />
      <component :is="loadingComponent" v-else-if="loading" class="sp-quantum-select-card__loading">
        Loading...
      </component>
      <div v-else-if="!hideNoData" class="sp-quantum-select-card__content-no-result">
        <slot name="no-data">
          {{ noDataText }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { cardProps } from "./props.js";
</script>

<script setup>
/**
 * QuantumSelectCard is an extension of QuantumSelect that renders a single card within the QuantumSelect component.
 * It is designed to be used within the QuantumSelect component.
 *
 * @displayName QuantumSelectCard
 * @group Custom Elements
 * @component sp-quantum-select-card
 */
import { computed } from "vue";
import { toBoolean } from "../../utils/props";
import { isObjectLike } from "../../utils/types";

const emit = defineEmits(["update-selected", "update-search", "next"]);

const props = defineProps(cardProps);

const loading = computed(() => toBoolean(props.loading));
const hideNoData = computed(() => toBoolean(props.hideNoData));
const hideSearchBar = computed(() => toBoolean(props.hideSearchBar));

/**
 * The loading component to display when loading.
 * The default is a div element with the text "Loading...".
 *
 * Mid term: This should be a component which should be configurable via defaults.
 *
 * @type {String}
 */
const loadingComponent = computed(() => props.loadingComponent ?? "div");

const items = computed(() =>
  props.items?.map((item) => {
    if (isObjectLike(item)) {
      return { ...item, props: extendItemProps(item) };
    }
    return item;
  }),
);

const hasItems = computed(() => items.value.length > 0);

function extendItemProps(item) {
  const props = {
    ...(item.props ?? {}),
  };
  if (item.hasChildren) {
    props.selectable = false;
    props.appendIcon = "chevron-right-filled";
  }
  return props;
}

function onUpdateSelected({ detail }) {
  const [value] = detail;

  return emit("update-selected", value);
}

function onItemSelect({ detail }) {
  const [item] = detail;

  if (item?.hasChildren) {
    emit("next", item);
  }
}
</script>

<style>
:host {
  display: block;
  overflow: hidden;
  height: 100%;
  position: relative;
}
</style>
<style lang="scss" scoped>
sp-search-box {
  margin-inline: var(--quantum-select-content-padding);
}

.sp-quantum-select-card {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  max-height: 100%;
  min-width: var(--quantum-select-popup-width);
}

.sp-quantum-select-card__header:not(:empty) {
  margin-bottom: var(--sp-comp-quantum-select-card-row-gap, var(--sp-ref-spacing-6, 0.75rem));
}

.sp-quantum-select-card__content {
  position: relative;
  overflow-y: var(--sp-comp-quantum-select-card-content-overflow-y, auto);
}

.sp-quantum-select-card__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.sp-quantum-select-card__content-no-result {
  padding: var(--sp-comp-quantum-select-card-no-result-padding, 1rem);
  color: var(--sp-comp-quantum-select-card-no-result-color, #aaa);
}

sp-list {
  --list-item-inline-padding: var(--quantum-select-content-padding);
  --sp-comp-list-item-append-margin-right: 0;
  --sp-comp-list-item-prepend-icon-fill-hover: #777;
}
</style>
