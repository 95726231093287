import { defineStore } from "pinia";
import { ref } from "vue";
import { useStatusApi } from "../api/status";
import { Status } from "../models/status";

export const useSharedStatusStore = (options) => {
  const api = options.statusApi ?? useStatusApi(options.url);

  const init = defineStore("status", () => {
    const status = ref(null);

    async function load(insurancePlanId) {
      status.value = await api.status(insurancePlanId);
    }

    return {
      // state
      status,

      // actions
      load,
    };
  });

  return init();
};

export const useStatusStore = (attributes) => ({
  // state
  status: Status.from(attributes),
});
