<template>
  <sp-dialog class="sp-confirm" :class="classModifiers" :open.prop="isOpen" no-backdrop persistent>
    <div class="sp-confirm__header">
      <div class="sp-confirm__title">
        <slot name="title">
          {{ title }}
        </slot>
      </div>

      <div @click="close">
        <slot name="close">
          <sp-button v-if="closeable" class="sp-confirm__close" label="x" variant="text-inline" />
        </slot>
      </div>
    </div>

    <div class="sp-confirm__content">
      <slot>
        {{ content }}
      </slot>
    </div>

    <div class="sp-confirm__actions">
      <div @click="cancel">
        <slot name="cancel">
          <sp-button v-if="cancelable" color="secondary" class="sp-confirm__action-cancel">
            {{ cancelLabel }}
          </sp-button>
        </slot>
      </div>

      <div @click="confirm">
        <slot name="confirm">
          <sp-button color="primary" class="sp-confirm__action-confirm">
            {{ confirmLabel }}
          </sp-button>
        </slot>
      </div>
    </div>
  </sp-dialog>
</template>

<script setup>
import { useStorage } from "@vueuse/core";
import { ref, useSlots, watch } from "vue";

const slots = useSlots();
const emit = defineEmits(["confirm", "cancel"]);

const props = defineProps({
  /**
   * The model value of the component.
   * If true the dialog will be shown.
   *
   * @type {Boolean}
   * @default true
   */
  open: {
    type: Boolean,
    default: true,
  },
  /**
   * The title of the dialog.
   * If a slot with the name "title" is provided this prop will be ignored.
   *
   * @type {String}
   * @default undefined
   */
  title: {
    type: String,
    default: undefined,
  },
  /**
   * The content of the dialog.
   * If a slot with the name "default" is provided this prop will be ignored.
   *
   * @type {String}
   * @default undefined
   */
  content: {
    type: String,
    default: undefined,
  },
  /**
   * If true a close button will be rendered in the top right corner.
   *
   * @type {Boolean}
   * @default false
   */
  closeable: {
    type: Boolean,
    default: false,
  },
  /**
   * If true a cancel button will be rendered in the bottom action bar.
   * If a slot with the name "cancel" is provided this prop will be ignored.
   *
   * @type {Boolean}
   * @default false
   */
  cancelable: {
    type: Boolean,
    default: false,
  },
  /**
   * The label of the cancel button.
   * If a slot with the name "cancel" is provided this prop will be ignored.
   *
   * @type {String}
   * @default "Cancel"
   */
  cancelLabel: {
    type: String,
    default: "Cancel",
  },
  /**
   * The label of the confirm button.
   * If a slot with the name "confirm" is provided this prop will be ignored.
   *
   * @type {String}
   * @default "Confirm"
   */
  confirmLabel: {
    type: String,
    default: "Confirm",
  },
  /**
   * If set the confirmation will be persisted in the browser's local storage and will not be shown again.
   * The value of this prop will be used as the value of the local storage key.
   * If not set the confirmation will appear again on every page reload.
   *
   * @type {String}
   * @default undefined
   */
  persist: {
    type: String,
    default: undefined,
  },
});

const classModifiers = {
  "--closeable": props.closeable || slots.close,
  "--cancelable": props.cancelable || slots.cancel,
};

const isConfirmed = props.persist ? useStorage(props.persist, false) : ref(false);

const shouldOpen = (open) => {
  if (open && props.persist) {
    return !isConfirmed.value;
  }
  return open;
};

const isOpen = ref(shouldOpen(props.open));

watch(
  () => props.open,
  (value) => (isOpen.value = shouldOpen(value)),
);

function cancel() {
  close();
  emit("cancel");
}

function confirm() {
  isConfirmed.value = true;
  close();
  emit("confirm");
}

function close() {
  isOpen.value = false;
}
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.sp-confirm {
  &.--closeable {
    // TODO: @mgruschetzki: add close button definition
  }
  &.--cancelable {
    // TODO: @mgruschetzki: add cancel button definition
  }
}

.sp-confirm__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--sp-ce-confirm-border-color, var(--sp-sys-border-color, #dcdfe6));
}

.sp-confirm__title {
  font-size: 1.25rem;
  font-weight: 500;
  // TODO: @mgruschetzki: add definition
}

.sp-confirm__close {
  // TODO: @mgruschetzki: add close button definition
}

.sp-confirm__content {
  padding: 1rem;
  // TODO: @mgruschetzki: add definition
}

.sp-confirm__actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  // TODO: @mgruschetzki: add definition
}

.sp-confirm__action-cancel {
  // TODO: @mgruschetzki: add cancel button definition
}

.sp-confirm__action-confirm {
  // TODO: @mgruschetzki: add confirm button definition
}
</style>
