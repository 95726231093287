<template>
  <slot />
</template>
<style>
:host {
  --aspect-ratio: inherit;
  display: block;
  min-width: var(--sp-ce-slide-min-width, 100%);
  display: flex;
  align-items: center;
  justify-content: var(--sp-comp-slide-justify-content, flex-start);
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  aspect-ratio: var(--aspect-ratio);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  box-sizing: border-box;
  transition: aspect-ratio 5s ease-in-out;
}
</style>
