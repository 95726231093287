<template>
  <div class="sp-text-field">
    <div class="sp-text-field-content">
      <div class="sp-text-field-prepend">
        <slot name="prepend" @click="onPrependClick">
          <sp-icon v-if="prependIcon" :name="prependIcon" class="sp-text-field-content__icon" />
        </slot>
      </div>
      <div class="sp-text-field-content__input-container">
        <slot />
        <input
          class="sp-text-field-content__input-field"
          :type="type"
          :value="model"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :inputmode="inputmode"
          @input.stop.prevent="onInput"
        />
      </div>
      <div class="sp-text-field-append">
        <slot name="append" class="append-slot" @click.stop="onAppendClick">
          <sp-icon v-if="appendIcon" :name="appendIcon" class="sp-text-field-content__icon" />
        </slot>
      </div>
    </div>

    <sp-linear-progress-bar v-if="loading" class="sp-text-field__loader" />
  </div>
</template>

<script>
import { props as propsDefinitions } from "./props.js";
</script>

<script setup>
import { computed, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["input", "click-append", "click-prepend"]);

const props = defineProps({
  ...propsDefinitions,
});

const loading = computed(() => toBoolean(props.loading));
const disabled = computed(() => toBoolean(props.disabled));
const readonly = computed(() => toBoolean(props.readonly));

const model = ref(props.value);
watch(model, () => emit("input", model.value));
watch(
  () => props.value,
  (value) => (model.value = value),
);

function onInput(event) {
  model.value = event.target.value;
}

function onPrependClick() {
  emit("click-prepend");
}

function onAppendClick() {
  emit("click-append");
}
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-text-field {
  border-width: var(--sp-comp-text-field-border-width, var(--sp-sys-form-field-border-width, 1px));
  border-style: var(--sp-comp-text-field-border-style, var(--sp-sys-form-field-border-style, solid));
  border-color: var(--sp-comp-text-field-border-color, var(--sp-sys-form-field-border-color, #ccc));
  border-radius: var(--sp-comp-text-field-border-radius, var(--sp-sys-form-field-border-radius, 0.3rem));
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-width: var(--sp-comp-text-field-min-width, 10ch);
  background-color: var(--sp-comp-text-field-background-color, var(--sp-sys-form-field-background-color, #fff));
}

.sp-text-field-content {
  position: relative;
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  grid-template-areas: "prepend-inner field append-inner";
  align-items: center;
  padding-inline: var(--sp-comp-text-field-content-padding-inline, 0);
  padding-block: var(--sp-comp-text-field-content-padding-block, 0);
}
.sp-text-field-prepend {
  grid-area: prepend-inner;
  min-height: 100%;
}
.sp-text-field-append {
  grid-area: append-inner;
  min-height: 100%;
}

.sp-text-field-prepend,
.sp-text-field-append {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-text-field-content__input-container {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  cursor: var(--sp-comp-text-field-cursor, text);
}

.sp-text-field-content__input-field {
  padding-inline: var(--sp-comp-text-field-padding-inline, 1rem);
  padding-block: var(--sp-comp-text-field-padding-block, 0);
  display: flex;
  flex: 1;
  border: none;
  outline: none;
  font-family: var(--sp-comp-text-field-font, var(--sp-sys-form-field-font, sans-serif));
  font-size: var(--sp-comp-text-field-font-size, var(--sp-sys-form-field-font-size, 1rem));
  background-color: transparent;
  width: 100%;
  min-width: var(--sp-comp-text-field-min-width, 10ch);
  min-height: var(--sp-comp-text-field-min-height, var(--sp-sys-form-field-min-height, 3rem));
  width: var(--sp-comp-text-field-width, 100%);
  color: var(--sp-comp-text-field-color, var(--sp-sys-form-field-color, #000));
  cursor: var(--sp-comp-text-field-cursor, text);

  &::placeholder {
    color: var(--sp-comp-text-field-placeholder-color, var(--sp-sys-form-field-placeholder-color, #ccc));
  }

  &[inputmode="none"] {
    cursor: pointer;
  }
  &[readonly] {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.sp-text-field__loader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
