<template>
  <div ref="root" class="sp-quantum-select-navigation-stack" :class="rootModifierClass">
    <div v-if="showNavbar" class="navbar" :class="navbarModifierClasses">
      <sp-button
        class="navbar__leading-action"
        size="small"
        icon="chevron-left-filled"
        color="surface"
        @click="$emit('pop')"
      />
      <div v-if="!hideTitleBar" class="navbar-title-container">
        <transition v-if="currentStackItem?.title" :name="transitionNameNavbarTitle">
          <div :key="currentStackItem.title" class="navbar__title" :class="navbarModifierClasses">
            {{ currentStackItem.title }}
          </div>
        </transition>
      </div>
    </div>

    <div class="before-content-slot">
      <slot name="before-content" />
    </div>
    <div class="content">
      <transition v-if="currentStackItem" :name="transitionName">
        <component
          v-bind="componentProps"
          :is="component"
          :key="currentStackItem.key"
          class="slide"
          @update-search="({ detail }) => $emit('update-search', ...detail)"
          @update-selected="({ detail }) => emit('update-selected', ...detail)"
          @next="({ detail }) => emit('next', ...detail)"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { cardProps } from "./props.js";
</script>

<script setup>
import { computed, ref } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { useExpose } from "../../composables/expose";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["update-selected", "update-search", "next", "pop"]);

const props = defineProps({
  ...cardProps,

  /**
   * An indicator to show if the current card has a parent.
   * If true, the back button will be shown in the navigation bar.
   *
   * @type {Boolean}
   * @default false
   */
  hasParent: {
    type: [Boolean, String],
    default: false,
  },
  hideTitleBar: {
    type: [Boolean, String],
    default: false,
  },

  width: {
    type: String,
    default: undefined,
  },

  maxHeight: {
    type: String,
    default: "var(--quantum-select-popup-min-height)",
  },
});

const hideTitleBar = ref(toBoolean(props.hideTitleBar));

const hasParent = computed(() => toBoolean(props.hasParent));

const root = ref(null);
const component = "sp-quantum-select-card";
const { exposeMethods } = useExpose(root);

const stack = ref([]);
const currentStackItem = computed(() => stack.value.at(-1));

const providedComponentProps = computed(() =>
  Object.keys(cardProps).reduce((acc, key) => {
    const value = props[key];

    if (value !== undefined) {
      acc[key] = value;
    }

    return acc;
  }, {}),
);

const componentProps = computed(() => ({
  ...providedComponentProps.value,
  ...(currentStackItem.value?.props ?? {}),
}));

exposeMethods({
  push,
  pop,
  update,
});

const direction = ref("push");

const transitionName = computed(() => `slide-content-${direction.value}`);
const transitionNameNavbarTitle = computed(() => `slide-navbar-title-${direction.value}`);

const hasChildren = computed(() => currentStackItem.value?.items?.some((item) => item.children?.length > 0));
const showNavbar = computed(() => !hideTitleBar.value || hasParent.value || hasChildren.value);

function update(props) {
  currentStackItem.value.props = props;
}

function push(props, title) {
  direction.value = "push";
  stack.value.push(createStackItem(props, title));
}

/**
 * Pop the current stack item.
 * If the stack has only one item, it will unshift the provided props and title.
 *
 * @param {Object} props The props to update the previous stack item with.
 * @param {String} title The title to update the previous stack item with.
 */
function pop(props, title) {
  // If the stack has only one item, unshift the provided props and title
  if (stack.value.length <= 1) {
    stack.value.unshift(createStackItem(props, title));
  }
  // Otherwise, update the previous stack item with the provided props and title
  else if (props && stack.value.length > 1) {
    stack.value.at(-2).props = props;
  }

  direction.value = "pop";
  stack.value.pop();
}

function createStackItem(props, title) {
  return { key: Math.random(), props, title };
}

const navbarModifierClasses = computed(() => ({
  "--leading-action-visible": hasParent.value,
  "--has-title-bar": !hideTitleBar.value,
}));

const { isGtSmScreen } = useBreakpoints(root);

const rootModifierClass = computed(() => ({
  "--bp-lt-sm": !isGtSmScreen.value,
}));
</script>

<style>
:host {
  display: block;
  position: relative;
  height: 100%;
  overflow: hidden;
}
</style>

<style scoped lang="scss">
.sp-quantum-select-navigation-stack {
  --navbar-title-x-position: 0;

  min-height: var(--quantum-select-popup-min-height);
  min-width: var(--quantum-select-popup-width);

  height: 100%;
  max-height: v-bind(maxHeight);

  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto 1fr;

  &.--bp-lt-sm {
    max-height: 100%;
  }
}

.navbar {
  --navbar-gap: var(--sp-comp-quantum-select-navigation-stack-navbar-gap, 0.25rem);
  display: flex;
  gap: var(--navbar-gap);
  padding-inline: var(--quantum-select-content-padding);
  min-height: var(--sp-comp-quantum-select-navigation-stack-nav-bar-min-height, 2.5rem);
  align-items: center;
  margin-top: var(--sp-comp-quantum-select-navigation-stack-nav-bar-margin-top, 0.25rem);
  position: relative;
  overflow: hidden;

  &.--leading-action-visible {
    --leading-action-opacity: 1;
    --leading-action-translate-x: 0%;
  }

  sp-button {
    margin-left: calc(var(--navbar-gap) * -1);
  }
}

.navbar-title-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.navbar__title {
  font-family: var(--sp-sys-font-family-bold);
  font-weight: var(--sp-sys-font-weight-bold);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0;
  transform: translateX(var(--navbar-title-x-position));

  &.--leading-action-visible {
    --navbar-title-x-position: 2rem;
  }
}

.navbar__leading-action {
  --sp-ce-button-focus-opacity: 0;
  --sp-ce-button-focus-visible-opacity: 1;
  --sp-ce-button-bg-color: linear-gradient(to right, white 66%, transparent 100%);

  opacity: var(--leading-action-opacity, 0);
  width: var(--leading-action-width, 2rem);
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  transform: var(--leading-action-translate-x, -100%);
  transition: all var(--quantum-select-navigation-stack-animation-time) ease-in-out;
  z-index: 9;
}

.content {
  padding-top: var(--quantum-select-content-padding-top, 0.5rem);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
}

.navbar + .before-content-slot + .content {
  padding: 0;
}

.slide {
  min-width: 100%;
}

// TRANSITION CLASSES
.slide-navbar-title-pop-leave-to,
.slide-navbar-title-push-enter-from,
.slide-content-pop-leave-to,
.slide-content-push-enter-from {
  transform: translateX(100%);
}

.slide-navbar-title-pop-enter-to,
.slide-navbar-title-push-enter-to {
  transform: translateX(var(--navbar-title-x-position));
}

.slide-content-pop-enter-to,
.slide-content-push-enter-to {
  transform: translateX(0%);
}

.slide-navbar-title-pop-enter-from,
.slide-navbar-title-push-leave-to,
.slide-content-pop-enter-from,
.slide-content-push-leave-to {
  transform: translateX(-100%);
}

.slide-navbar-title-pop-enter-active,
.slide-navbar-title-pop-leave-active,
.slide-navbar-title-push-enter-active,
.slide-navbar-title-push-leave-active {
  transition: all var(--quantum-select-navigation-stack-animation-time) ease-in-out;
  position: absolute;
}

.slide-content-pop-enter-active,
.slide-content-pop-leave-active,
.slide-content-push-enter-active,
.slide-content-push-leave-active {
  transition: var(
    --sp-comp-quantum-select-navigation-stack-transition,
    all var(--quantum-select-navigation-stack-animation-time) ease-in-out
  );
  position: absolute;
}

.slide-navbar-title-pop-enter-from,
.slide-navbar-title-push-leave-to,
.slide-navbar-title-pop-leave-to,
.slide-navbar-title-push-enter-from {
  opacity: 0;
}

.slide-navbar-title-pop-enter-to,
.slide-navbar-title-push-enter-to {
  opacity: 1;
}
</style>
