<template>
  <div class="sp-ai-generator-app">
    <sp-modal modal-title="AI Content Generator" ignore-escape-key :open="open" :width="modalSize" @close="closeApp">
      <div class="content">
        <ErrorDialog v-if="error" :error="error" @retry="retryGenerateContent" @cancel="closeApp" />

        <ContentView
          :original-content="originalContent"
          :compare-mode="compareMode"
          :candidates="candidates"
          :selected-index="indexOfSelectedCandidate"
          @update-selected-index="handleUpdateSelectedIndex"
          @remove-error-candidates="removeErrorCandidates"
          @update-compare-mode="compareMode = $event"
        />
      </div>

      <ActionBar
        slot="actions"
        :candidates="candidates"
        :selected-candidate="selectedCandidate"
        :has-many="hasManyCandidates"
        :has-previous="hasPreviousCandidate"
        :has-next="hasNextCandidate"
        @select-previous="selectPreviousCandidate"
        @select-next="selectNextCandidate"
        @generate-content="generateContent"
        @keep-original="closeApp"
        @use-selected="useSelectedCandidate"
        @select-candidate="selectCandidate"
      />
    </sp-modal>
  </div>
</template>

<script setup>
/**
 * The `SpAiGeneratorApp` component is a custom element that generates content using the SparkleAI API.
 *
 * @emits update-model-value - When the selected content is updated.
 * @emits close - When the modal is closed.
 */
import { computed, onMounted, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";
import ActionBar from "./components/ActionBar.ce.vue";
import ContentView from "./components/ContentView.ce.vue";
import ErrorDialog from "./components/ErrorDialog.ce.vue";
import { useGeneratorStore } from "./store/generator.js";

const emit = defineEmits(["update-model-value", "close"]);

const props = defineProps({
  open: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * The URL to use for the content generation.
   * @type {String}
   */
  url: {
    type: String,
    required: true,
  },
  /**
   * Whether to send the value to the API for generation.
   * @type {Boolean|String}
   */
  input: {
    type: [Boolean, String],
    default: true,
  },
  /**
   * The initial content value.
   * @type {String}
   */
  modelValue: {
    type: String,
    default: "",
  },
});

const generatorStore = useGeneratorStore(props.url, toBoolean(props.input));
const {
  candidates,
  hasManyCandidates,
  hasNextCandidate,
  hasPreviousCandidate,
  selectedCandidate,
  indexOfSelectedCandidate,
  selectCandidate,
  selectNextCandidate,
  selectPreviousCandidate,
  removeCandidate,
  removeErrorCandidates,
  clear,
} = generatorStore;

const mounted = ref(false);
const error = ref(null);

const compareMode = ref(false);

const modalSize = computed(() => (compareMode.value ? "100ch" : "70ch"));

onMounted(() => {
  mounted.value = true;
  reload();
});

const originalContent = ref(props.modelValue);
watch(
  () => props.modelValue,
  (value) => (originalContent.value = value),
);
watch(originalContent, reload);

const open = ref(props.open);
watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);
watch(open, handleOpenChange, { immediate: true });

/**
 * Handles the open change event.
 * When the modal is opened, it reloads the content and generates new content.
 *
 * @param {Boolean} isOpen - Whether the modal is open.
 */
function handleOpenChange(isOpen) {
  if (isOpen) {
    reload();
  }
}

/**
 * Resets the content and generates new content.
 * It also selects the first candidate as the selected candidate.
 *
 * @returns {Promise<void>}
 */
async function reload() {
  if (!mounted.value) {
    return;
  }
  clear();
  await generateContent();
  selectCandidate(candidates.value?.[0]);
}

/**
 * Retries generating the content.
 */
function retryGenerateContent() {
  removeCandidate(selectedCandidate.value);
  generateContent(selectCandidate);
}

/**
 * Generates content using the original content.
 *
 * @returns {Promise<void>}
 */
async function generateContent(candidate = null) {
  try {
    error.value = null;
    await generatorStore.generateContent(originalContent.value, candidate);
  } catch (err) {
    error.value = err;
  }
}

function useSelectedCandidate() {
  emit("update-model-value", selectedCandidate.value?.content);
}

function closeApp() {
  open.value = false;
  emit("close");
}

function handleUpdateSelectedIndex(index) {
  selectCandidate(candidates.value?.[index]);
}
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-ai-generator-app {
  display: block;
}

.content {
  position: relative;
  padding-top: 0.75rem;
}
</style>
