<template>
  <div ref="container" class="menu" :class="containerModifierClass" @keydown.esc="onKeyDownEscape">
    <div v-if="!isPermanent" ref="activator" @click.prevent.stop="onActivatorClick">
      <slot name="activator" class="menu-activator" />
    </div>

    <sp-bottom-sheet
      v-if="showBottomSheet"
      :open="isActive"
      :permanent="isPermanent"
      :full-height="mobileFullHeight"
      @close="isActive = false"
    >
      <slot />
      <sp-button slot="action" class="bottom-sheet-close-action" color="primary">Close</sp-button>
    </sp-bottom-sheet>
    <sp-popup
      v-else
      :active="isActive"
      :location="location"
      :pointer="pointer"
      :permanent="isPermanent"
      :disabled="disabled"
      @click.prevent.stop="onContentClick"
    >
      <slot />
    </sp-popup>
  </div>
</template>

<script setup>
/**
 * The SpMenu component shows a menu at the position of the element used to activate it.
 *
 * @displayName Menu
 * @group Custom Elements
 * @component sp-menu
 */
import { onClickOutside } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["input"]);

const props = defineProps({
  value: {
    type: [Boolean, String],
    default: false,
  },
  id: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  closeOnContentClick: {
    type: [Boolean, String],
    default: true,
  },
  closeOnOutsideClick: {
    type: [Boolean, String],
    default: true,
  },
  pointer: {
    type: [Boolean, String],
    default: false,
  },
  mobileFullHeight: {
    type: [Boolean, String],
    default: false,
  },
  location: {
    type: String,
    default: "bottom",
    validator: (value) => ["bottom", "bottom-left", "bottom-right"].includes(value),
  },
  permanent: {
    type: [Boolean, String],
    default: false,
  },
});

const isActive = ref(toBoolean(props.value));
const isPermanent = ref(toBoolean(props.permanent));

watch(isActive, (value) => {
  emit("input", value);
});

watch(
  () => props.value,
  (value) => (isActive.value = toBoolean(value)),
);

const closeOnContentClick = computed(() => toBoolean(props.closeOnContentClick));
const closeOnOutsideClick = computed(() => toBoolean(props.closeOnOutsideClick));

const container = ref(null);

const { isGtSmScreen } = useBreakpoints(container);

const containerModifierClass = computed(() => ({
  "--bp-lt-sm": !isGtSmScreen.value,
}));

function onActivatorClick() {
  isActive.value = !isActive.value;
}

function onContentClick() {
  if (closeOnContentClick.value && isActive) {
    isActive.value = false;
  }
}

const activator = ref(null);

let clickOutsideStop;
const showBottomSheet = computed(() => !isGtSmScreen.value);

watch(isActive, (value) => {
  if (!closeOnOutsideClick.value || showBottomSheet.value) {
    return;
  }
  if (value) {
    clickOutsideStop = onClickOutside(container, () => (isActive.value = false));
  } else if (clickOutsideStop) {
    clickOutsideStop();
  }
});

function onKeyDownEscape() {
  if (showBottomSheet.value) {
    return;
  }
  isActive.value = false;
}
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.menu {
  position: relative;
}
.menu-activator {
  cursor: pointer;
  display: block;
  position: relative;
}
sp-bottom-sheet {
  --bottom-sheet-border-radius: 8px 8px 0 0;
  --sp-comp-bottom-sheet-content-padding-inline: 0;
  --sp-comp-bottom-sheet-content-padding-block: 0.5rem;
  --sp-comp-bottom-sheet-action-container-padding-inline: 0.5rem;
}
.bottom-sheet-close-action {
  --button-width: 100%;
}
</style>
