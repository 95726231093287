<template>
  <div class="sp-card-content">
    <slot />
  </div>
</template>

<script setup></script>

<style>
:host {
  display: block;
}
</style>
<style scoped>
.sp-card-content {
  display: block;
  padding-inline: var(--sp-card-padding-inline, 1rem);
  padding-block: var(--sp-card-padding-block, 0.5rem);
}
</style>
