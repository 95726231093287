import { props as listProps } from "../SpList/props.js";
import { props as searchBoxProps } from "../SpSearchBox/props.js";

export const cardProps = Object.freeze({
  ...listProps,
  ...searchBoxProps,

  /**
   * Text input used to filter items.
   *
   * @type {String}
   * @default undefined
   */
  search: {
    type: String,
    default: undefined,
  },
  /**
   * Hide the search bar.
   *
   * @type {Boolean}
   * @default false
   */
  hideSearchBar: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Hides the no data slot.
   *
   * @type {Boolean}
   * @default false
   */
  hideNoData: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Text input used to filter items.
   *
   * @type {String}
   * @default undefined
   */
  noDataText: {
    type: String,
    default: "No data",
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
});
