import { computed } from "vue";
import { toKebabCase } from "../../shared/utils/string";

/**
 * Create a list of modifiers based on the keys and the props.
 * It will create a list of modifiers based on the keys and the props.
 *
 * If the value is falsy, it will be ignored.
 * If the value is truthy, it will create a modifier with the key and the value.
 *
 * @param {Object} props
 * @param {Array<String>} keys
 * @param {Object} options
 * @param {Boolean} options.withValue
 * @returns Object
 */
function createModifiers(props, keys, options = {}) {
  const withValue = options?.withValue ?? false;
  const modifiers = [];

  for (const key of keys) {
    const value = props[key];
    if (!value) {
      continue;
    }
    if (withValue) {
      modifiers.push(createModifier(key, value));
    } else {
      modifiers.push(createModifier(key));
    }
  }

  return modifiers;
}

/**
 * Create a modifier based on the segments.
 * It sanitizes the segments and joins them with a separator.
 *
 * @param  {...String} segments
 * @returns String
 *
 * @example
 * createModifier("size", "sm") // "--size-sm"
 * createModifier("color", "red", "special case") // "--color-red-special-case"
 * createModifier("some value") // "--some-value"
 *
 */
function createModifier(...segments) {
  const separator = "-";
  return `--${segments.map(toKebabCase).join(separator).replace(/\s/g, separator)}`;
}

/**
 * A composable to create modifiers based on the props and the keys with values.
 *
 * @example
 * props: {
 *  size: "sm",
 *  color: "red",
 * }
 * const { modifiers } = useModifiers(props, "size", "color");
 * modifiers.value // ["--size-sm", "--color-red"]
 *
 * @param {Object} props
 * @param  {...String} keys
 * @returns Object
 * @property {import("vue").ComputedRef<string[]>} modifiers
 */
export function useModifiers(props, ...keys) {
  const modifiers = computed(() => createModifiers(props, keys, { withValue: true }));

  return {
    modifiers,
  };
}

/**
 * A composable to create modifiers based on the props and the keys.
 *
 * @example
 * props: {
 *   icon: true,
 * }
 * const { booleanModifiers } = useBooleanModifiers(props, "icon");
 * booleanModifiers.value // ["--icon"]
 *
 * @param {Object} props
 * @param  {...String} keys
 * @returns Object
 * @property {import("vue").ComputedRef<string[]>} booleanModifiers
 */
export function useBooleanModifiers(props, ...keys) {
  const booleanModifiers = computed(() => createModifiers(props, keys));

  return {
    booleanModifiers,
  };
}
