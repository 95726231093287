<template>
  <div
    ref="list"
    class="sp-list"
    role="listbox"
    :aria-activedescendant="focusedIndex"
    :aria-multiselectable="multiple"
    tabindex="-1"
  >
    <sp-list-subheader v-if="props.subheader">{{ props.subheader }}</sp-list-subheader>

    <template v-for="(item, index) in items" :key="index">
      <sp-list-subheader v-if="isHeader(item)" :title="item.header" />

      <sp-list-item
        v-else
        :id="indexOfListItem(index)"
        :tabindex="focusedIndex === indexOfListItem(index) ? 0 : -1"
        :active="isSelected(item)"
        :active-prepend-icon="selectedPrependIcon"
        :aria-selected="focusedIndex === indexOfListItem(index)"
        :title="getItemTitle(item)"
        :subtitle="getItemSubtitle(item)"
        :value="getItemValue(item)"
        :left-indentation="hasItemsWithPrependIcon"
        :right-indentation="hasItemsWithAppendIcon"
        :readonly="readonly"
        v-bind="getItemProps(item)"
        @click="onItemClick(item)"
      />
    </template>
  </div>
</template>

<script>
import { props as listProps } from "./props.js";
</script>

<script setup>
import { onKeyStroke, useFocus } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { toArray, toBoolean } from "../../utils/props";
import { useSpList } from "./composable";

const emit = defineEmits(["update-selected", "select-item"]);

const props = defineProps({
  ...listProps,
});

const {
  getItemProps,
  getItemTitle,
  getItemValue,
  getItemSubtitle,
  isHeader,
  isSelectable,
  indexOfListItem,
  listItemOfIndex,
  listItemIndexes,
} = useSpList(props);

const list = ref(null);

/**
 * Check if the list has items with a prepend icon.
 * If the selectedPrependIcon prop is defined or any item has a prepend icon, it will return true.
 *
 * This is used to determine if the list items should have left indentation.
 */
const hasItemsWithPrependIcon = computed(
  () => props.selectedPrependIcon !== undefined || props.items?.some((item) => "prependIcon" in getItemProps(item)),
);

/**
 * Check if the list has items with an append icon.
 * If any item has an append icon, it will return true.
 *
 * This is used to determine if the list items should have right indentation.
 */
const hasItemsWithAppendIcon = computed(() => props.items?.some((item) => "appendIcon" in getItemProps(item)));

/**
 * If `listenOnKeyEvent` is true, the list will listen to key events and navigate through the list.
 * If `listenOnKeyEvent` is "focus", the list will listen to key events only when it is focused.
 * Otherwise, it will not listen to key events and all existing key event listeners will be removed.
 */
const { focused } = useFocus(list);
const listenOnKeyEvent = computed(
  () => toBoolean(props.listenOnKeyEvent) || (props.listenOnKeyEvent === "focus" && focused.value),
);

/**
 * The key stroke listeners for navigation and selection.
 * These listeners will be removed when the component is unmounted or when `listenOnKeyEvent` is false.
 */
let navigationKeyStrokeListener;
let selectionKeyStrokeListener;

const selectionKeys = ["Enter", " "];
const navigationKeys = ["ArrowDown", "ArrowUp", "Home", "End"];

watch(
  listenOnKeyEvent,
  (value) => {
    if (value) {
      selectionKeyStrokeListener = onKeyStroke(selectionKeys, handleSelectionKeyStroke, { dedupe: true });
      navigationKeyStrokeListener = onKeyStroke(navigationKeys, handleNavigationKeyStroke);
    } else {
      selectionKeyStrokeListener?.();
      navigationKeyStrokeListener?.();
    }
  },
  { immediate: true },
);

function handleSelectionKeyStroke(e) {
  e.stopPropagation();
  e.preventDefault();
  return conditionallyUpdateSelection();
}

function handleNavigationKeyStroke(e) {
  e.preventDefault();
  e.stopPropagation();

  focus(e.key);
}

const focusedIndex = ref(-1);

watch(focusedIndex, (index) => {
  if (index >= 0) {
    const el = list.value.querySelector(`sp-list-item[id='${index}']`);
    const behavior = index === 0 || index === listItemIndexes.value.length - 1 ? "auto" : "smooth";
    el?.focus();
    el?.scrollIntoView({ block: "nearest", inline: "nearest", behavior });
  }
});

/**
 * Focus the list item based on the key.
 *
 * If the key is "Home", the first item will be focused.
 * If the key is "End", the last item will be focused.
 * If the key is "ArrowDown", the next item will be focused. If the last item is focused, the first item will be focused.
 * If the key is "ArrowUp", the previous item will be focused. If the first item is focused, the last item will be focused.
 *
 * @param {KeyboardEvent} e
 * @param {string} key
 */
function focus(key) {
  const numberOfItems = listItemIndexes.value.length;

  if (numberOfItems === 0) {
    focusedIndex.value = -1;
  }

  if (key === "Home") {
    focusedIndex.value = 0;
  } else if (key === "End") {
    focusedIndex.value = numberOfItems - 1;
  } else if (key === "ArrowDown") {
    const newIndex = focusedIndex.value + 1;
    focusedIndex.value = newIndex >= numberOfItems ? 0 : newIndex;
  } else if (key === "ArrowUp") {
    const newIndex = focusedIndex.value - 1;
    focusedIndex.value = newIndex < 0 ? numberOfItems - 1 : newIndex;
  }
}

const multiple = computed(() => toBoolean(props.multiple));

const model = ref(props.selected ? toArray(props.selected) : []);
watch(model, (value) => emit("update-selected", value));
watch(
  () => props.selected,
  (value) => (model.value = toArray(value)),
);

function conditionallyUpdateSelection() {
  if (focusedIndex.value !== null) {
    const item = listItemOfIndex(focusedIndex.value);
    updateSelection(item);
    emit("select-item", item);
  }
}

function isSelected(item) {
  if (!isSelectable(item)) {
    return false;
  }

  return model.value.includes(getItemValue(item));
}

function onItemClick(item) {
  emit("select-item", item);
  updateSelection(item);
}

function updateSelection(item) {
  if (!isSelectable(item)) {
    return;
  }

  toggleSelection(item);
}

/**
 * Toggle the selection of the item.
 *
 * If the multiple prop is true, the item will be added to the selection if it is not selected.
 * If the multiple prop is true, the item will be removed from the selection if it is selected.
 *
 * If the multiple prop is false, the item will be selected if it is not selected.
 * If the multiple prop is false, the item will be unselected if it is selected.
 *
 * @param {Object} item
 */
function toggleSelection(item) {
  if (multiple.value) {
    toggleMultipleSelection(item);
  } else {
    toggleSingeSelection(item);
  }
}

function toggleMultipleSelection(item) {
  const value = getItemValue(item);
  const index = model.value.indexOf(value);

  if (index === -1) {
    model.value.push(value);
  } else {
    model.value.splice(index, 1);
  }
}

function toggleSingeSelection(item) {
  const value = getItemValue(item);
  if (model.value?.[0] === value) {
    model.value = [];
  } else {
    model.value = [value];
  }
}
</script>

<style>
:host {
  display: block;
  --list-min-width: var(--sp-comp-list-min-width, auto);
}
</style>

<style scoped lang="scss">
.sp-list {
  min-width: var(--list-min-width);
  width: 100%;
}

sp-list-item:hover:not([active]) + sp-list-item,
sp-list-item:focus:not([active]) + sp-list-item,
sp-list-item:focus-visible:not([active]) + sp-list-item {
  --divider-width: 100%;
  --sp-comp-divider-color: var(--sp-comp-list-item-hover-background-color, #ccc);
  --divider-left: 0;
}

sp-list-subheader + sp-list-item,
sp-list-item:first-child {
  --display-divider: none;
}
</style>
