<template>
  <div class="sp-bottom-sheet" :class="modifierClasses">
    <slot name="activator" @click.stop="open = true" />
    <sp-dialog
      position="bottom"
      width="100vw"
      :persistent="persistent"
      :open="open"
      @close="handleDialogClose"
      @update-open="handleDialogUpdateOpen"
    >
      <div class="sp-bottom-sheet__content" @click.stop.prevent>
        <div class="sp-bottom-sheet__dialog-slot">
          <slot />
        </div>
        <div class="sp-bottom-sheet__action">
          <slot name="action" @click="open = false" />
        </div>
      </div>
    </sp-dialog>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";
import { props as dialogProps } from "../SpDialog/props.js";
</script>

<script setup>
const emit = defineEmits(["close", "update-open"]);

const props = defineProps({
  ...dialogProps,
  fullHeight: {
    type: [Boolean, String],
    default: false,
  },
});

const open = ref(toBoolean(props.open));

watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

watch(open, (value) => {
  emit("update-open", value);
  if (!value) {
    emit("close");
  }
});

const modifierClasses = computed(() => ({
  "--full-height": props.fullHeight,
}));

function handleDialogClose() {
  open.value = false;
}

function handleDialogUpdateOpen({ detail }) {
  const [value] = detail;
  open.value = value;
}
</script>

<style>
:host {
  display: block;
  --bottom-sheet-border-radius: var(--sp-comp-bottom-sheet-border-radius, 0);
}
</style>

<style lang="scss" scoped>
.sp-bottom-sheet {
  --bottom-sheet-max-height: var(--sp-comp-bottom-sheet-content-max-height, 80vh);
  --bottom-sheet-height: auto;
}

.sp-bottom-sheet.--full-height {
  --bottom-sheet-height: var(--bottom-sheet-max-height);
}

.sp-bottom-sheet__content {
  display: grid;
  grid-template-rows: 1fr auto;
  box-sizing: border-box;
  position: relative;
  max-height: var(--bottom-sheet-max-height);
  min-height: 0;
  height: var(--bottom-sheet-height);
  border-radius: var(--bottom-sheet-border-radius);
  background-color: var(--sp-comp-bottom-sheet-background-color, var(--sp-sys-color-surface, white));
  padding-inline: var(--sp-comp-bottom-sheet-content-padding-inline, 1rem);
  padding-block: var(--sp-comp-bottom-sheet-content-padding-block, 1rem);
}

sp-dialog {
  --sp-comp-dialog-margin: 0;
  --sp-comp-dialog-padding: 0;
  --sp-comp-dialog-background: transparent;
  --sp-comp-dialog-width: 100vw;
  --sp-comp-dialog-border-radius: var(--bottom-sheet-border-radius);
}

.sp-bottom-sheet__dialog-slot {
  position: relative;
  overflow: hidden;
}

.sp-bottom-sheet__action {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-inline: var(--sp-comp-bottom-sheet-action-container-padding-inline, 1rem);
  ::slotted(*) {
    margin-top: 1rem;
  }
}
</style>
