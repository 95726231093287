export const props = Object.freeze({
  /**
   * The model value of the component.
   * If true the dialog will be shown.
   *
   * @type {Boolean}
   * @default false
   */
  open: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether to hide the backdrop or not.
   * If true the backdrop will not be rendered.
   *
   * @type {Boolean}
   * @default false
   */
  noBackdrop: {
    type: Boolean,
    default: false,
  },
  /**
   * If set to true, the dialog will not be closed when the Esc key is pressed.
   * If set to false, the dialog will be closed when the Esc key is pressed.
   *
   * @type {Boolean}
   * @default false
   */
  ignoreEscapeKey: {
    type: [Boolean, String],
    default: false,
  },
  width: {
    type: String,
    default: "300px",
  },
  maxWidth: {
    type: String,
    default: "none",
  },
  height: {
    type: String,
    default: "fit-content",
  },
  maxHeight: {
    type: String,
    default: "none",
  },
  /**
   * If true the dialog will not be deactivated when clicking outside of it or pressing esc key.
   *
   * @type {Boolean}
   * @default false
   */
  persistent: {
    type: Boolean,
    default: false,
  },
  /**
   * If true the dialog will be fullscreen.
   *
   * @type {Boolean}
   * @default false
   */
  fullscreen: {
    type: Boolean,
    default: false,
  },
});
