import { BaseApi } from "../../../../shared/api/base";
import { InsurancePlan } from "../models/insurance-plan";

export class InsuranceFilterApi extends BaseApi {
  constructor(options) {
    super({ ...options, model: InsurancePlan });
  }

  transform(responseData) {
    if (!responseData) {
      return null;
    }

    const { meta, data } = responseData;
    return {
      meta,
      data: super.transform(data),
    };
  }
}

export const useInsuranceFilterApi = ({ sections, api, baseUrl, url }) => {
  const options = {};

  // If a URL is provided, use it as the base URL.
  // This is useful for dedicated endpoints such as provider insurance plans.
  if (url) {
    options.baseURL = url;
  } else {
    options.baseURL = baseUrl;
    options.api = api;
  }

  const instance = new InsuranceFilterApi(options);

  return {
    all: async () => instance.get("", { sections }),
  };
};
