export class Status {
  static LOADING = "loading";
  static SUCCESS = "success";
  static ERROR = "error";

  constructor(value) {
    this.value = value;
  }

  get isLoading() {
    return this.value === Status.LOADING;
  }

  get isSuccess() {
    return this.value === Status.SUCCESS;
  }

  get isError() {
    return this.value === Status.ERROR;
  }
}
