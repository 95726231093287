<template>
  <sp-menu
    ref="container"
    class="sp-select"
    :class="modifierClass"
    :value="menuOpen"
    @input="onMenuOpenChange"
    @keydown.down="onMenuKeyDown"
  >
    <div slot="activator" class="sp-select-activator">
      <slot name="activator">
        <sp-text-field
          class="sp-select-activator__input"
          :inputmode="inputmode"
          :readonly="inputmode === 'none'"
          :value="textValue"
          :disabled="disabled"
          :placeholder="placeholder"
          :prepend-icon="prependIcon"
          :append-icon="appendIcon"
          :type="type"
          :loading="loading"
          @click.prevent.stop="menuOpen = !menuOpen"
          @input="onTextInput"
        >
          <div slot="prepend">
            <slot name="prepend" @click="onPrependClick"></slot>
          </div>
          <div slot="append">
            <slot name="append" @click="onAppendClick">
              <sp-caret v-if="caretVisible" :active="menuOpen" />
            </slot>
          </div>
        </sp-text-field>
      </slot>
    </div>

    <div v-if="listContainerVisible" class="sp-select-list-container">
      <sp-list
        v-if="hasItems"
        class="sp-select-list"
        :items="items"
        :item-title="itemTitle"
        :item-value="itemValue"
        :item-subtitle="itemSubtitle"
        :multiple="multiple"
        :selected="model"
        :listen-on-key-event="menuOpen"
        @update-selected="onUpdateSelected"
      />
      <div v-else-if="!loading && !hideNoData" class="sp-select-list__no-result">
        <slot name="no-data">
          {{ noDataText }}
        </slot>
      </div>
    </div>
  </sp-menu>
</template>

<script setup>
import { onClickOutside } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["input", "text-input", "click-append", "click-prepend", "update-menu"]);

const props = defineProps({
  /**
   * Sets the input’s placeholder text.
   *
   * @type {String}
   * @default undefined
   */
  placeholder: {
    type: String,
    default: undefined,
  },
  /**
   * Can be an array of objects or strings.
   *
   * By default objects should have title and value properties.
   * Keys to use for these can be changed with the item-title, item-value, and item-props props.
   * If strings are used, they will be used for both title and value.
   *
   * @type {Array<String|Object>}
   * @default []
   */
  items: {
    type: Array,
    default: () => [],
  },
  /**
   * Property on supplied items that contains its title.
   *
   * @type {String}
   * @default "title"
   */
  itemTitle: {
    type: String,
    default: "title",
  },
  /**
   * Property on supplied items that contains its subtitle.
   *
   * @type {String}
   * @default undefined
   */
  itemSubtitle: {
    type: String,
    default: undefined,
  },
  /**
   * Property on supplied items that contains its value.
   *
   * @type {String}
   * @default "value"
   */
  itemValue: {
    type: String,
    default: "value",
  },
  /**
   * Creates a sp-icon component before default content in the prepend slot.
   *
   * @type {String}
   * @default undefined
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Creates a sp-icon component after default content in the append slot.
   *
   * @type {String}
   * @default undefined
   */
  appendIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Removes the ability to click or target the input.
   *
   * @type {Boolean}
   * @default false
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Removes the ability to edit the input’s value.
   *
   * @type {String}
   * @default "none"
   */
  inputmode: {
    type: String,
    default: "none",
    validator: (value) => {
      const validValues = ["none", "text", "decimal", "numeric", "tel", "search", "email", "url"];
      return validValues.includes(value);
    },
  },
  /**
   * The model value of the component.
   * If the component supports the multiple prop, this defaults to an empty array.
   *
   * @type {String|Array|Object}
   * @default null
   */
  value: {
    type: [String, Array, Object],
    default: null,
  },
  /**
   * Changes select to multiple.
   * Accepts array for value.
   *
   * @type {Boolean}
   * @default false
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * Shows a loading indicator.
   *
   * @type {Boolean}
   * @default false
   */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * Changes the selection behavior to return the object directly rather than the value specified with item-value.
   *
   * @type {Boolean}
   * @default false
   */
  returnObject: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets the input’s type attribute.
   *
   * @type {String}
   * @default "text"
   */
  type: {
    type: String,
    default: "text",
  },
  /**
   * Hides the no data slot.
   *
   * @type {Boolean}
   * @default false
   */
  hideNoData: {
    type: Boolean,
    default: false,
  },
  /**
   * Text input used to filter items.
   *
   * @type {String}
   * @default undefined
   */
  noDataText: {
    type: String,
    default: "No data",
  },
  menu: {
    type: Object,
    default: () => ({}),
  },
});

const container = ref(null);

const loading = computed(() => toBoolean(props.loading));
const hideNoData = computed(() => toBoolean(props.hideNoData));
const returnObject = computed(() => toBoolean(props.returnObject));
const multiple = computed(() => toBoolean(props.multiple));
const disabled = computed(() => toBoolean(props.disabled));

const menuOpen = ref(toBoolean(props.menu));
watch(menuOpen, (value) => emit("update-menu", value));

function onMenuOpenChange({ detail }) {
  const [value] = detail;
  menuOpen.value = value;
}

function onMenuKeyDown() {
  if (!menuOpen.value) {
    menuOpen.value = true;
  }
}

const modifierClass = computed(() => ({
  "--disabled": props.disabled,
  "--is-active": menuOpen.value,
}));

const model = ref(props.value ?? (multiple.value ? [] : null));
watch(model, (value) => {
  emit("input", returnObject.value ? findItem(value) : value);
});
watch(
  () => props.value,
  (value) => (model.value = value),
);

const textValue = computed(() => {
  if (multiple.value) {
    return model.value?.length ? `${model.value.length} selected` : "";
  }

  const item = findItem(model.value);
  return item?.[props.itemTitle] ?? item;
});

function findItem(value) {
  return props.items.find((item) => String(item[props.itemValue]) === String(value));
}

const hasItems = computed(() => props.items?.length > 0);
const caretVisible = computed(() => hasItems.value);
const listContainerVisible = computed(() => hasItems.value || !hideNoData.value);

function onPrependClick() {
  emit("click-prepend");
}

function onAppendClick() {
  emit("click-append");
}

function onTextInput({ detail }) {
  const [value] = detail;
  emit("text-input", value);
}

function onUpdateSelected({ detail }) {
  const [value] = detail;
  model.value = value;

  // This is required to close the menu after a selection is made and all events have been handled.
  nextTick(() => (menuOpen.value = false));
}

onClickOutside(container, () => {
  menuOpen.value = false;
});
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-select-list-container {
  position: relative;
  width: 100%;
}

.sp-select-list__no-result {
  padding: var(--sp-ce-select-no-result-padding, 1rem);
  color: var(--sp-ce-select-no-result-color, #aaa);
}
</style>
