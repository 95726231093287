import { BaseApi } from "../../../../shared/api/base";
import { Status } from "../models/status";

export class StatusApi extends BaseApi {
  constructor() {
    super({ api: "insurance_plan", model: Status });
  }

  async get(url, insurancePlanId) {
    const query = {};
    if (insurancePlanId) {
      query.insurance_plan = insurancePlanId;
    }
    return super.get(url, query);
  }
}

let instance;

export const useStatusApi = (url) => {
  instance ??= new StatusApi();

  return {
    status: async (insurancePlanId) => instance.get(url, insurancePlanId),
  };
};
