import { ref, watch } from "vue";
import { useHost } from "./host";

class Queue {
  constructor() {
    this.queue = [];
  }

  add(value) {
    this.queue.push(value);
  }

  get length() {
    return this.queue.length;
  }

  /**
   * Dequeue all values.
   *
   * @return {Generator} A generator that yields the values in the queue.
   * @yields {any}
   */
  *dequeueAll() {
    while (this.queue.length > 0) {
      yield this.queue.shift();
    }
  }
}

/**
 * Expose properties and methods to the host element.
 * The properties and methods are exposed when the host element is available.
 * The properties and methods are exposed only once.
 * The properties and methods are exposed in the order they are added.
 *
 * @param {import('vue').VueElement} root
 * @returns {Object}
 * @property {Function} exposeProperties
 * @property {Function} exposeMethods
 */
export function useExpose(root) {
  const { host } = useHost(root);

  /**
   * Watch the host element.
   * When the host element is available, drain the properties and methods queue.
   */
  watch(host, () => {
    drainPropertiesQueue();
    drainMethodsQueue();
  });

  const methodsQueue = ref(new Queue());
  const propertiesQueue = ref(new Queue());

  function exposeProperties(properties) {
    propertiesQueue.value.add(properties);
  }

  function drainPropertiesQueue() {
    if (!host.value || propertiesQueue.value.length === 0) {
      return;
    }
    for (const properties of propertiesQueue.value.dequeueAll()) {
      defineProperties(properties);
    }
  }

  function defineProperties(properties) {
    for (const [key, value] of Object.entries(properties)) {
      Reflect.defineProperty(host.value, key, value);
    }
  }

  watch(propertiesQueue, () => drainPropertiesQueue(), { deep: true });

  function exposeMethods(methods) {
    methodsQueue.value.add(methods);
  }

  function drainMethodsQueue() {
    if (!host.value) {
      return;
    }
    for (const methods of methodsQueue.value.dequeueAll()) {
      Object.assign(host.value, methods);
    }
  }

  watch(methodsQueue, () => drainMethodsQueue(), { deep: true });

  return {
    /**
     * Expose properties to the host element.
     *
     * @param {Object} properties
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperties
     *
     * @example
     * exposeProperties({
     *  property1: {
     *   value: "value1",
     *  },
     *  property2: {
     *   get: () => "value2",
     *   set: (value) => [...]
     *  },
     * });
     */
    exposeProperties,

    /**
     * Expose methods to the host element.
     *
     * @param {Object} methods
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
     *
     * @example
     * exposeMethods({
     *  method1() {
     *   console.log("method1");
     *  },
     *  method2() {
     *   console.log("method2");
     *  },
     * });
     */
    exposeMethods,
  };
}
