<template>
  <sp-select
    ref="container"
    inputmode="search"
    :no-data-text="noDataText"
    :items="items"
    :item-title="itemTitle"
    :hide-no-data="!hasSearchInput"
    :menu="hasSearchInput"
    @input="onInput"
    @text-input="onSearchInput"
    @click-append="$emit('click-append')"
    @click-prepend="$emit('click-prepend')"
  />
</template>

<script setup>
import { onClickOutside } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["input", "click-append", "click-prepend", "update-search"]);

const props = defineProps({
  /**
   * Can be an array of objects or strings.
   *
   * By default objects should have title and value properties.
   * Keys to use for these can be changed with the item-title, item-value, and item-props props.
   * If strings are used, they will be used for both title and value.
   *
   * @type {Array<String|Object>}
   * @default []
   */
  items: {
    type: Array,
    default: () => [],
  },
  /**
   * Property on supplied items that contains its title.
   *
   * @type {String}
   * @default "title"
   */
  itemTitle: {
    type: String,
    default: "title",
  },
  /**
   * Text to display when there are no items.
   *
   * @type {String}
   * @default "No results found"
   */
  noDataText: {
    type: String,
    default: "No results found",
  },
  /**
   * The model value of the component.
   * If the component supports the multiple prop, this defaults to an empty array.
   *
   * @type {String|Array|Object}
   * @default null
   */
  value: {
    type: [String, Array, Object],
    default: null,
  },
  /**
   * Text input used to filter items.
   *
   * @type {String}
   * @default undefined
   */
  search: {
    type: String,
    default: undefined,
  },
  /**
   * Do not apply filtering when searching.
   * Useful when data is being filtered server side.
   *
   * @type {Boolean}
   * @default false
   */
  noFilter: {
    type: Boolean,
    default: false,
  },
});

const model = ref(props.value);
watch(
  () => props.value,
  (value) => (model.value = value),
);
watch(model, (value) => emit("input", value));

const search = ref(props.search);
watch(
  () => props.search,
  (value) => (search.value = value),
);
watch(search, (value) => emit("update-search", value));

const hasSearchInput = computed(() => search.value?.length > 0);

const noFilter = computed(() => toBoolean(props.noFilter));

const items = computed(() => {
  if (noFilter.value || !hasSearchInput.value) {
    return props.items;
  }
  return props.items.filter((item) => {
    const title = item?.[props.itemTitle] ?? item;
    return title?.toLowerCase().includes(search.value?.toLowerCase?.());
  });
});

function onInput({ detail }) {
  const [value] = detail;
  model.value = value;
}

function onSearchInput({ detail }) {
  const [value] = detail;
  search.value = value;
}

const container = ref(null);
onClickOutside(container, () => {
  if (model.value === null) {
    search.value = null;
  }
});
</script>

<style>
:host {
  display: block;
}
</style>
