import { Model } from "../../../../shared/models/model";

const iconNameMap = {
  undefined: "insurance-carrier-color",
  accepted: "insurance-innetwork-color",
  "no-provider-data": "insurance-unclear-color",
  "not-accepted": "insurance-outofnetwork-color",
};

const alertTypeMap = {
  undefined,
  accepted: "success",
  "no-provider-data": "warning",
  "not-accepted": "error",
};

export class Status extends Model {
  static from(attributes) {
    const { status, insurancePlan, provider } = attributes;
    return new Status({ status, insurancePlan: { name: insurancePlan }, provider: { name: provider } });
  }

  get defaults() {
    return {
      status: null,
    };
  }

  get isUndefined() {
    return this.status === "undefined";
  }

  get isNotAccepted() {
    return this.status === "not-accepted";
  }

  get isAccepted() {
    return this.status === "accepted";
  }

  get isNoProviderData() {
    return this.status === "no-provider-data";
  }

  get isSelfPay() {
    return this.status === "self-pay";
  }

  get icon() {
    return iconNameMap[this.status];
  }

  get type() {
    return alertTypeMap[this.status];
  }

  get insuranceName() {
    return this.insurancePlan?.name;
  }

  get providerName() {
    return this.provider?.name;
  }
}
