<template>
  <div class="sp-divider" :class="classModifiers"></div>
</template>
<script setup>
import { computed } from "vue";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  vertical: {
    type: [Boolean, String],
    default: false,
  },
});

const classModifiers = computed(() => ({
  "--vertical": toBoolean(props.vertical),
}));
</script>
<style>
:host {
  display: var(--sp-comp-divider-display, block);
}
</style>

<style lang="scss" scoped>
.sp-divider {
  height: var(--sp-comp-divider-height, 1px);
  background-color: var(--sp-comp-divider-color, #ccc);
  margin-block: var(--sp-comp-divider-margin-block, 0);
  margin-inline: var(--sp-comp-divider-margin-inline, 0);

  &.--vertical {
    width: var(--sp-comp-divider-width, 1px);
    height: 100%;
  }
}
</style>
