import { useMediaQuery, useMutationObserver } from "@vueuse/core";
import { ref, watch } from "vue";

function getPropertyValue(propery) {
  return window.getComputedStyle(document.documentElement).getPropertyValue(propery);
}

/**
 * Create a callback function to be called when the breakpoint changes
 * and update the receiver ref with the new value.
 *
 * It also watches the media query to update the receiver ref when the
 * media query changes.
 *
 * It keeps track of the watcher to unwatch it when the breakpoint changes.
 *
 * @param {import("vue").Ref} receiver
 * @param {import("vue").Ref} breakpoint
 * @returns Function
 */
function createOnChangeBreakpointCallback(receiver, breakpoint) {
  let unwatch;

  return () => {
    unwatch?.();

    const watched = useMediaQuery(`(min-width: ${breakpoint.value})`);
    receiver.value = watched.value;

    unwatch = watch(watched, (value) => (receiver.value = value));
  };
}

/**
 * A composable to get the current breakpoints and if the screen is greater than
 * a specific breakpoint.
 *
 * @returns {{
 *  smBreakpoint: import("vue").Ref<string>,
 *  xlBreakpoint: import("vue").Ref<string>,
 *  isGtSmScreen: import("vue").Ref<boolean>,
 *  isGtXlScreen: import("vue").Ref<boolean>,
 * }}
 */
export function useBreakpoints() {
  const smBreakpoint = ref("");
  const isGtSmScreen = ref(false);
  watch(smBreakpoint, createOnChangeBreakpointCallback(isGtSmScreen, smBreakpoint));

  const xlBreakpoint = ref("");
  const isGtXlScreen = ref(false);
  watch(xlBreakpoint, createOnChangeBreakpointCallback(isGtXlScreen, xlBreakpoint));

  useMutationObserver(
    document.documentElement,
    () => {
      smBreakpoint.value = getPropertyValue("--sp-ref-breakpoint-sm");
      xlBreakpoint.value = getPropertyValue("--sp-ref-breakpoint-xl");
    },
    {
      subtree: true,
      attributes: true,
      attributeFilter: ["class", "style"],
    },
  );

  return {
    smBreakpoint,
    xlBreakpoint,
    isGtSmScreen,
    isGtXlScreen,
  };
}
