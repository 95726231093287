import { BaseApi } from "../../../../shared/api/base";
import { Candidate } from "../models/candidate";

class GeneratorApi extends BaseApi {
  constructor(options) {
    super({ ...options, api: "field_content_generator", model: Candidate });
  }
}

export function useGeneratorApi(urlString) {
  const url = new URL(urlString);
  const { origin, pathname, searchParams } = url;

  const params = Object.fromEntries(searchParams.entries());
  const api = new GeneratorApi({ baseURL: origin });

  return {
    async generateContent(data = {}) {
      return await api.post(pathname, { ...data, ...params });
    },
  };
}
