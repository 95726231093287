import * as vue from "vue";
import { toKebabCase } from "../shared/utils/string";

/**
 * Registers all custom elements.
 *
 * @param {vue.ComponentObjectPropsOptions} props - Default values for the component props.
 */
export function register(props = {}) {
  registerComponents(props);
  registerAppComponents(props);

  // Set the visibility of the custom elements to visible.
  // This is required to prevent a flickering effect when the custom elements are loaded.
  // See app/assets/stylesheets/custom_elements/setup.scss.erb for more information.
  document.documentElement.style.setProperty("--sp-ce-visibility", "visible");

  // Indicates to the wider app that the custom elements have been loaded
  window.spElementsInitialized = true;
}

/**
 * Registers all components in the components directory.
 * @param {vue.ComponentObjectPropsOptions} props - Default values for the component props.
 */
function registerComponents(props) {
  const components = import.meta.glob("./components/**/*.ce.vue", { eager: true });

  for (const [path, component] of Object.entries(components)) {
    const elementConstructor = defineCustomElement(component.default, props);
    registerCustomElement(path.split("/").at(-1), elementConstructor);
  }
}

/**
 * Registers all components in the apps directory.
 * @param {vue.ComponentObjectPropsOptions} props - Default values for the component props.
 */
function registerAppComponents(props) {
  const apps = import.meta.glob("./apps/**/main.js", { eager: true });

  for (const [path, app] of Object.entries(apps)) {
    const elementConstructor = defineAppCustomElement(app, props);
    registerCustomElement(path.split("/").at(-2), elementConstructor);
  }
}

/**
 * Defines a custom element for compatibility.
 * These custom elements are used to wrap Vue 3 components in a custom element.
 * That way we can use the full power of Vue 3 components, including a store etc. in a custom element.
 *
 * @param {Object} main - The main object of the app. It must contain a setup function that returns the component and plugins.
 *
 * @param {vue.ComponentObjectPropsOptions} props - Default values for the component props.
 * @property {string} embedHost - The embed host.
 *
 * @returns {vue.VueElementConstructor<{}>}
 */
function defineAppCustomElement(main, props) {
  const { component, plugins } = main.setup(props);

  return vue.defineCustomElement({
    ...component,
    props: enrichProps(component, props),

    setup() {
      const app = vue.createApp();

      // If plugins are defined, use them.
      plugins?.forEach((plugin) => app.use(plugin));

      // re-assign required properties to the current instance, they might be used by the component plugins
      const inst = vue.getCurrentInstance();
      Object.assign(inst.appContext, app._context);
      Object.assign(inst.provides, app._context.provides);
    },

    render: ({ $props, $emit }) => {
      const domEventName = (eventName) => `on${eventName[0].toUpperCase()}${eventName.slice(1)}`;
      const events = Object.fromEntries(
        (component.emits ?? []).map((eventName) => [domEventName(eventName), (payload) => $emit(eventName, payload)]),
      );

      return vue.h(component, {
        ...$props,
        ...events,
      });
    },
  });
}

/**
 * Defines a custom element.
 * If applicable, the props passed to the custom element will be used as the default values for the component props.
 *
 * @param {vue.VueElement} component
 * @param {vue.ComponentObjectPropsOptions} props - Default values for the component props.
 * @returns {vue.VueElementConstructor<{}>}
 */
function defineCustomElement(component, props) {
  return vue.defineCustomElement({
    ...component,
    props: enrichProps(component, props),
  });
}

/**
 * Registers a custom element.
 *
 * The name of the custom element will be derived from the custom element file name.
 * The custom element will be registered under the "sp-" prefix.
 * For example, the custom element file name "MyComponent.ce.vue" will be registered as "sp-my-component".
 *
 * @param {string} path
 * @param {vue.VueElementConstructor<{}>} elementConstructor
 */
function registerCustomElement(fileName, elementConstructor) {
  const componentName = toKebabCase(fileName.replace(".ce.vue", ""));
  const isAlreadyRegistered = customElements.get(componentName);

  if (isAlreadyRegistered) {
    return;
  }

  /**
   * A custom element that is aware of the form association.
   * This is required to use the custom element in a form.
   *
   * TODO: @sleistner - conditionally set formAssociated based on the component settings.
   *
   * @see https://html.spec.whatwg.org/multipage/custom-elements.html#concept-element-form-associated
   */
  const FormAwareComponentClass = class extends elementConstructor {
    static formAssociated = true;
  };

  customElements.define(componentName, FormAwareComponentClass);
}

/**
 * Enriches the component props with the props passed to the custom element.
 *
 * @param {vue.VueElement} component
 * @param {vue.ComponentObjectPropsOptions} props
 * @returns vue.ComponentObjectPropsOptions
 */
function enrichProps(component, props) {
  const reducer = (acc, [key, value]) => {
    if (value.external && key in props) {
      value = { ...value, default: props[key] };
    }

    return { ...acc, [key]: value };
  };

  return Object.entries(component.props ?? {}).reduce(reducer, {});
}
