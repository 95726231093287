<template>
  <sp-menu
    class="sp-quantum-select"
    :value="menuOpen"
    :close-on-content-click="closeOnContentClick"
    :pointer="pointer"
    :mobile-full-height="mobileFullHeight"
    :location="location"
    :permanent="permanent"
    :width="width"
    @input="onMenuInput"
  >
    <div slot="activator" class="sp-quantum-select__activator">
      <slot name="activator">
        <sp-button>Open menu</sp-button>
      </slot>
    </div>
    <div class="sp-quantum-select__content">
      <sp-quantum-select-navigation-stack
        ref="stack"
        :item-title="props.itemTitle"
        :item-value="props.itemValue"
        :item-subtitle="props.itemSubtitle"
        :has-parent="hasParent"
        :title="title"
        :clearable="clearable"
        :listen-on-key-event="menuOpen"
        :selected="model"
        :no-data-text="props.noDataText"
        :hide-no-data="props.hideNoData"
        :hide-search-bar="props.hideSearchBar"
        :hide-title-bar="props.hideTitleBar"
        :max-height="props.maxHeight"
        :readonly="props.readonly"
        :loading="loading"
        :placeholder="placeholder"
        @update-selected="onUpdateSelected"
        @update-search="onSearchInput"
        @next="onNext"
        @pop="onPop"
      >
        <div slot="before-content">
          <slot name="before-content" />
        </div>
      </sp-quantum-select-navigation-stack>
    </div>
  </sp-menu>
</template>

<script>
import { useArrayDifference } from "@vueuse/core";
import { cardProps } from "./props.js";
</script>

<script setup>
/**
 * QuantumSelect is an advanced web component designed to elevate the traditional select element into a highly
 * interactive and user-friendly interface.
 * It seamlessly integrates deep nested structure support, section headers, and a dynamic search input,
 * enabling users to effortlessly navigate and filter through complex datasets.
 *
 * @displayName QuantumSelect
 * @group Custom Elements
 * @component sp-quantum-select
 */

import { computed, onMounted, ref, watch } from "vue";
import { toArray, toBoolean } from "../../utils/props";
import { useSpList } from "../SpList/composable";

const emit = defineEmits(["update-selected", "update-search", "update-open"]);

const props = defineProps({
  ...cardProps,

  open: {
    type: [Boolean, String],
    default: false,
  },
  title: {
    type: String,
    default: undefined,
  },
  permanent: {
    type: [Boolean, String],
    default: false,
  },
  width: {
    type: String,
    default: undefined,
  },
  maxHeight: {
    type: String,
    default: undefined,
  },
  /**
   * Do not apply filtering when searching.
   * Useful when data is being filtered server side.
   *
   * @type {Boolean}
   * @default false
   */
  noFilter: {
    type: [Boolean, String],
    default: false,
  },
  hasParent: {
    type: [Boolean, String],
    default: false,
  },
  pointer: {
    type: [Boolean, String],
    default: false,
  },
  mobileFullHeight: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * A function that provides the parent and children items when navigating.
   * The function should be provided by the hosting component.
   * The function will be called with the parent or children item as the first argument.
   * The function should return a promise that resolves with void.
   * Once the promise resolves, the list of items need to be updated via the items prop.
   *
   * @type {Function}
   * @default undefined
   */
  provide: {
    type: Function,
    required: false,
    default: undefined,
  },
  location: {
    type: String,
    default: undefined,
  },
  hideTitleBar: {
    type: [Boolean, String],
    default: false,
  },
  closeOnSelect: {
    type: [Boolean, String],
    default: false,
  },
  closeOnContentClick: {
    type: [Boolean, String],
    default: false,
  },
});

/**
 * Handle the open state of the menu.
 * The menu can be toggled by emitting the input event with the new value.
 *
 * When the open state of the menu changes, we need to update the state and emit the update event.
 *
 * @type {import("vue").Ref<Boolean>}
 * @default false
 */
const menuOpen = ref(toBoolean(props.open));
watch(
  () => props.open,
  (value) => (menuOpen.value = toBoolean(value)),
);
watch(menuOpen, (value) => emit("update-open", value));

function onMenuInput({ detail }) {
  const [value] = detail;
  menuOpen.value = value;
}

const { getItemValue, getItemTitle } = useSpList(props);

const model = ref(toArray(props.selected));
watch(model, (newValue, oldValue) => {
  const difference = useArrayDifference(newValue, oldValue);

  // if the difference is empty and the new value is the same as the old value, we do not emit the update event
  if (difference.value.length === 0 && newValue?.length === oldValue?.length) {
    return;
  }
  emit("update-selected", toArray(newValue).map(getItemValue));

  if (toBoolean(props.closeOnSelect)) {
    menuOpen.value = false;
  }
});
watch(
  () => props.selected,
  (value) => (model.value = toArray(value)),
);

function onUpdateSelected({ detail }) {
  const [value] = detail;
  model.value = value;
}

const search = ref(props.search);
const hasSearchInput = computed(() => search.value?.length > 0);

watch(
  () => props.search,
  (value) => (search.value = value),
);
watch(search, (value) => emit("update-search", value));

function onSearchInput({ detail }) {
  const [value] = detail;
  search.value = value;
}

const noFilter = computed(() => toBoolean(props.noFilter));

/**
 * Filter the items based on the search input.
 * If the search input is empty or noFilter is true, the items will not be filtered.
 * If the search input is not empty, the items will be filtered based on the item title.
 *
 * @type {import("vue").ComputedRef<Array>}
 * @default []
 * @returns {Array}
 */
const filteredItems = computed(() => {
  const items = props.items ?? [];

  if (noFilter.value || !hasSearchInput.value) {
    return items;
  }

  return items.filter((item) => {
    if (item.header) {
      return true;
    }
    const title = getItemTitle(item);
    return title?.toLowerCase().includes(search.value.toLowerCase());
  });
});

/**
 * The items to be displayed in the select.
 * If the items are grouped, the group will be displayed as a header.
 * If the items are not grouped, they will be displayed as a flat list.
 *
 * @example
 * // input items
 * [
 *  { value: "1", text: "Item 1", group: "Group 1" },
 *  { value: "2", text: "Item 2", group: "Group 1" },
 *  { value: "3", text: "Item 3", group: "Group 2" },
 * ]
 * // output items
 * [
 *  { header: "Group 1" },
 *  { value: "1", text: "Item 1", group: "Group 1" },
 *  { value: "2", text: "Item 2", group: "Group 1" },
 *  { header: "Group 2" },
 *  { value: "3", text: "Item 3", group: "Group 2" },
 * ]
 *
 * @type {import("vue").ComputedRef<Array>}
 * @default []
 */
const items = computed(() => {
  const items = filteredItems.value;
  const defaultGroup = "__default__";

  // first we group the items by the group property
  const groups = items.reduce((acc, item) => {
    const group = item.group ?? defaultGroup;
    acc[group] ??= [];
    acc[group].push(item);
    return acc;
  }, {});

  // if there is only one group and it is the default group, we return the items as is
  if (Object.keys(groups).length === 1 && defaultGroup in groups) {
    return items;
  }

  // group default group into a alphanumerically sorted list
  if (defaultGroup in groups) {
    const alphanumericalGroup = groups[defaultGroup].reduce((acc, item) => {
      const firstChar = getItemTitle(item)?.charAt(0).toUpperCase();
      const key = isNaN(firstChar) ? firstChar : "#";
      acc[key] ??= [];
      acc[key].push(item);
      return acc;
    }, {});

    // remove the default group from the groups as it is now extracted into the alphanumerical group
    delete groups[defaultGroup];

    // sort the alphanumerical group
    const sortedKeys = Object.keys(alphanumericalGroup).sort();
    for (const key of sortedKeys) {
      groups[key] = alphanumericalGroup[key];
    }
  }

  // otherwise we include the group header before each group of items in the list and flatten the array
  return Object.keys(groups)
    .map((group) => [{ header: group }, ...groups[group]])
    .flat();
});

/**
 * If the items change, we need to update the stack with the new items.
 * We only react to the items change if the stack is not currently being updated.
 * This is to prevent an update on the wrong stack item.
 *
 * @type {import("vue").Ref<Boolean>}
 */
const reactOnItemsChange = ref(true);
watch(items, (value) => {
  if (!reactOnItemsChange.value) {
    return;
  }
  update(value);
});

const hasParent = computed(() => toBoolean(props.hasParent));

/**
 * Handle the next event from the navigation stack.
 * It requires the children items to be provided by the hosting component before navigating.
 *
 * @param {CustomEvent} event
 * @property {Array} detail
 * @returns {Promise<void>}
 */
async function onNext({ detail }) {
  const [item] = detail;
  await requestProvide("children", item, push);
}

/**
 * Handle the pop event from the navigation stack.
 * It requires the parent items to be provided by the hosting component before popping.
 *
 * @param {CustomEvent} event
 * @property {Array} detail
 * @returns {Promise<void>}
 */
async function onPop({ detail }) {
  const [item] = detail;
  await requestProvide("parent", item, pop);
}

async function requestProvide(type, item, done) {
  if (!props.provide) {
    return;
  }

  try {
    reactOnItemsChange.value = false;
    await props.provide(type, item);
    done();
  } finally {
    reactOnItemsChange.value = true;
  }
}

/**
 * This refence is used to push and pop the items when navigating.
 * Both the push and pop are exposed by the navigation stack component.
 */
const stack = ref(null);

const title = computed(() => props.title);

/**
 * Push the current items to the stack.
 * This is used to navigate to the next level.
 */
function push() {
  stack.value.push({ items: items.value }, title);
}

/**
 * Pop the last item from the stack.
 * This is used to navigate back to the previous level.
 */
function pop() {
  stack.value.pop({ items: items.value }, title);
}

function update(items) {
  stack.value.update({ items });
}
onMounted(() => push());
</script>

<style>
:host {
  display: block;
  --quantum-select-content-padding: var(--sp-comp-quantum-select-content-padding, 0.75rem);
  --quantum-select-popup-min-height: var(
    --sp-comp-quantum-select-popup-min-height,
    var(--sp-comp-popup-min-height, 25rem)
  );
  --quantum-select-popup-width: var(--sp-comp-quantum-select-popup-width, 18.5rem);
  --quantum-select-navigation-stack-animation-time: 3s;
}
</style>

<style lang="scss" scoped>
.sp-quantum-select__content {
  position: relative;
  height: 100%;
}
</style>
