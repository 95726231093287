<template>
  <div class="content-view" :class="classModifiers">
    <div class="original-view">
      <EditorView class="original-editor" :model="original" readonly no-animation :shrunk="!compareMode" />
    </div>
    <div class="slider-containter">
      <sp-slider
        :selected-index="selectedIndex"
        @update-selected-index="handleUpdateSelectedIndex"
        @scrollend="handleSliderScrollEnd"
      >
        <sp-slide v-for="(candidate, index) in candidates" :key="candidate.uuid">
          <EditorView :model="candidate" :selected="selectedIndex === index" />
        </sp-slide>
      </sp-slider>
    </div>
    <sp-switch
      :disabled="!compareModeSupported"
      label="Compare"
      :checked="compareMode"
      @update-checked="handleUpdateCompareMode"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import EditorView from "./EditorView.ce.vue";

const emit = defineEmits(["update-compare-mode", "remove-error-candidates", "update-selected-index"]);

const props = defineProps({
  originalContent: {
    type: String,
    required: true,
  },
  compareMode: {
    type: Boolean,
    default: false,
  },
  candidates: {
    type: Array,
    required: true,
  },
  selectedIndex: {
    type: Number,
    default: 0,
  },
});

/**
 * The original content.
 * We use a computed property to ensure that the content is reactive.
 *
 * @type {Object}
 */
const original = computed(() => ({
  content: props.originalContent,
  label: "Original",
}));

const compareModeSupported = computed(() => props.candidates?.length > 0 && !!props.originalContent?.trim());

const classModifiers = computed(() => ({
  "--compare-mode": props.compareMode,
}));

function handleUpdateCompareMode({ detail }) {
  const [value] = detail;
  emit("update-compare-mode", value);
}

function handleUpdateSelectedIndex({ detail }) {
  const [index] = detail;
  emit("update-selected-index", index);
}

function handleSliderScrollEnd() {
  emit("remove-error-candidates");
}
</script>

<style scoped lang="scss">
.content-view {
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  .original-view {
    transition: all 0.3s ease-in-out;
    width: 0%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .editor-view {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .original-view,
  .editor-view {
    min-height: 300px;
  }

  .original-view {
    position: relative;
  }

  .slider-containter {
    flex: 1;
    flex-grow: 1;
    overflow: hidden;
  }

  &.--compare-mode {
    .original-view {
      --gap: 1rem;
      width: calc(50% - var(--gap));
      margin-right: var(--gap);
    }
  }
}

sp-switch {
  position: absolute;
  top: 4px;
  right: 0;
  background-color: white;
}
</style>
