<template>
  <div class="editor-view" :class="classModifiers">
    <div class="editor-view__label">
      <label for="content">{{ model.label }}</label>
    </div>

    <div class="editor-view__content">
      <div v-if="loading" class="editor-view__status">
        <sp-animated-ellipsis size="large"></sp-animated-ellipsis>
      </div>
      <textarea v-else-if="isAnimatingText" id="content" :value="animatedText" readonly></textarea>
      <textarea v-else id="content" v-model="model.content" :readonly="readonly"></textarea>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, toRef, watch } from "vue";

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  noAnimation: {
    type: Boolean,
    default: false,
  },
});

const isAnimatingText = ref(false);
const animatedText = ref(null);

const status = computed(() => props.model.status);
const loading = computed(() => status.value?.isLoading || animatedText.value === null);

const model = toRef(props, "model");
const content = computed(() => model.value?.content);

/**
 * The minimum time to show the loading animation.
 */
const minimumLoadingTime = 1800;

const watchStopHandle = watch(content, startTextAnimation);

onMounted(() => startTextAnimation(content.value));

/**
 * Starts the text animation.
 * It will only animate the text one time.
 *
 *
 * @param {String} text The text to animate.
 */
function startTextAnimation(text) {
  if (!text) {
    return;
  }

  // Stop the watcher to prevent multiple animations
  watchStopHandle();

  if (props.noAnimation) {
    animatedText.value = text;
    return;
  }

  const createdAt = props.model.createdAt;
  const timeSinceCreation = Date.now() - createdAt;
  const delay = Math.max(minimumLoadingTime - timeSinceCreation, 0);

  setTimeout(() => generateAnimatedText(text), delay);
}

async function generateAnimatedText(text) {
  isAnimatingText.value = true;

  // If the text is very long, we need to increase the chunk size to make the animation smoother and faster.
  const chunkSize = Math.max(Math.floor(text.length / 800), 1);

  for (let i = 0; i <= text.length; i += chunkSize) {
    animatedText.value = text.slice(0, i) + "_";
    await new Promise((resolve) => setTimeout(resolve));
  }

  animatedText.value = text;
  isAnimatingText.value = false;
}

const classModifiers = computed(() => ({
  "--selected": props.selected,
}));
</script>

<style>
:host {
  display: block;
  width: 100%;
}
</style>

<style lang="scss" scoped>
.editor-view {
  --textarea-border-color: #ccc;
  --border-radius: var(--sp-sys-form-field-border-radius);
  --gap: 0.5rem;
  display: grid;
  grid-template-areas:
    "label"
    "content";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: var(--gap);
  width: 100%;
  min-width: 40ch;
  height: calc(100% - var(--gap));
  box-sizing: border-box;

  &.--selected {
    --textarea-border-color: var(--sp-sys-color-primary);
  }
}

.editor-view__label {
  grid-area: label;
}

.editor-view__content {
  grid-area: content;
  border: 1px solid var(--textarea-border-color);
  border-radius: var(--border-radius);
}

.editor-view__status {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

label {
  font-weight: var(--sp-sys-font-weight-bold);
  display: block;
  color: var(--sp-sys-color-on-surface);
}

textarea {
  box-sizing: border-box;
  color: var(--sp-sys-color-on-surface);
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
  font-family: var(--sp-sys-font-family-base);
  resize: none;
  box-sizing: border-box;
  box-shadow: none;
  background-color: transparent;

  &::placeholder {
    color: var(--sp-sys-form-field-placeholder-color);
  }
}
</style>
