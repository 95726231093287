export function getSlot(container) {
  const firstChild = container?.firstElementChild;

  if (!firstChild || firstChild.tagName !== "SLOT") {
    return undefined;
  }

  return firstChild;
}

export function getSlotContent(slot) {
  if (slot?.value?.firstElementChild?.tagName === "SLOT") {
    return slot?.value.firstElementChild.assignedElements();
  }

  return Array.from(slot?.value?.children ?? []);
}

export function hasSlotContent(slot) {
  const content = getSlotContent(slot);
  return content !== undefined && content.length > 0;
}
