import { Model } from "../../../../shared/models/model";

export class InsurancePlan extends Model {
  constructor(data) {
    const { icon_url: prependIconUrl, ...rest } = data;
    const props = { prependIconUrl };

    super({ ...rest, props });
  }
}
