import { computed, ref, watch } from "vue";
import { useInsuranceFilterApi } from "../api/insurance-filter";

const root = "root";

export const useInsuranceFilterStore = (options) => {
  const api = options.filterApi ?? useInsuranceFilterApi(options);
  const meta = ref(undefined);

  const loading = ref(false);
  const error = ref(null);

  /**
   * The list of parents to navigate through the filter list models.
   * The last parent is the current parent.
   * The first parent is the root.
   *
   * @type {import("vue").Ref<import("../../../../shared/models/model").Model[]>}
   */
  const parents = ref([]);
  const currentParent = computed(() => parents.value.at(-1) ?? root);
  const hasParent = computed(() => parents.value.length > 0 && currentParent.value !== root);

  /**
   * The lookup map for the filter list models.
   *
   * @type {import("vue").Ref<Map<string, import("../../../../shared/models/model").Model>>}
   */
  const modelLookupMap = ref(new Map());

  /**
   * Holds the list of filter list models to display.
   * The list is filtered based on the selected parent.
   *
   * @type {import("vue").Ref<import("../../../../shared/models/model").Model[]>}
   */
  const models = ref([]);

  /**
   * The currently selected model.
   *
   * @type {import("vue").Ref<import("../../../../shared/models/model").Model | undefined>}
   */
  const selectedModel = ref(undefined);

  watch(selectedModel, (model) => {
    activeParentForModel(model);
  });

  /**
   * Load the filter list models.
   * If a selected model is provided, the parent is set to the parent of the selected model.
   * Otherwise, the parent is set to the root.
   *
   * @param {string | undefined} selected - The value of the selected model.
   * @returns {Promise<void>}
   */
  async function load(selected = undefined) {
    loading.value = true;

    try {
      const response = await api.all();

      meta.value = response.meta;
      modelLookupMap.value = new Map(response.data.map((entry) => [entry.value, entry]));

      selectedModel.value = selected ? findByValue(selected) : undefined;

      pushParent(selectedModel.value?.parent ?? root);
      models.value = getChildrenByParent(parent);
    } catch (e) {
      error.value = e;
    } finally {
      loading.value = false;
    }
  }

  /**
   * The search query, which is used to filter the filter list models.
   * Once the search query changes, the filter list models are updated based on the search query.
   *
   * @type {import("vue").Ref<string>}
   * @default ""
   */
  const searchQuery = ref("");
  const sanitizedSearchQuery = computed(() => searchQuery.value.trim().toLowerCase());
  const hasSearchQuery = computed(() => sanitizedSearchQuery.value.length > 0);

  watch(searchQuery, search);

  /**
   * Search for filter list models.
   * The search algorithm is case-insensitive and starts from the children of the current parent.
   * If an element, which is a child of a parent, is found, the parent is used as the group.
   *
   * @param {string} query - The search query.
   */
  function search() {
    if (!hasSearchQuery.value) {
      models.value = getChildrenByParent(currentParent.value);
      return;
    }

    const leaves = getAllLeafNodes(currentParent.value);
    const result = leaves.filter(({ text }) => text.toLowerCase().includes(sanitizedSearchQuery.value));

    // if there are multiple parents, group the results by parent
    const uniqueParents = new Set(result.map(({ parent }) => parent));
    if (uniqueParents.size > 1) {
      models.value = result.map((model) => {
        const parent = findByValue(model.parent);
        const group = meta.value?.search?.[parent ? "leaves" : "root"];
        const subtitle = parent?.text;

        return {
          ...model,
          subtitle,
          group,
        };
      });
    } else {
      models.value = result;
    }
  }

  /**
   * Find an model by its value.
   *
   * @param {string} value - The value of the model to find.
   * @returns {import("../../../../shared/models/model").Model | undefined}
   */
  function findByValue(value) {
    return modelLookupMap.value.get(value);
  }

  /**
   * Group the filter models by their parent.
   * Also mark the filter models that have children.
   *
   * @type {import("vue").ComputedRef<Record<string, import("../../../../shared/models/model").Model[]>>}
   */
  const modelsGroupedByParent = computed(() => {
    const referencedParents = new Set(Array.from(modelLookupMap.value.values()).map((model) => model.parent));
    const groups = new Map();

    for (const model of modelLookupMap.value.values()) {
      const key = model.parent ?? root;
      if (!groups.has(key)) {
        groups.set(key, []);
      }
      const entry = groups.get(key);
      entry.push({ ...model, hasChildren: referencedParents.has(model.value) });
    }

    return groups;
  });

  /**
   * The title of the current step.
   *
   * @type {import("vue").ComputedRef<string>}
   * @default ""
   */
  const stepTitle = computed(() => {
    if (hasSearchQuery.value) {
      // TODO: @sleistner - i18n
      return "Search";
    }

    const index = parents.value.indexOf(currentParent.value);
    return meta.value?.steps[index]?.text ?? "";
  });

  /**
   * Get all leaf nodes of the parent.
   * The leaf nodes are the filter list models that have no children.
   *
   * @param {string} parent - The parent to get the leafs for.
   * @returns {import("../../../../shared/models/model").Model[]}
   */
  function getAllLeafNodes(parent) {
    const collectLeafNodes = (parent, leaves) => {
      const children = getChildrenByParent(parent);

      for (const child of children) {
        if (child.hasChildren) {
          collectLeafNodes(child.value, leaves);
        } else {
          leaves.push(child);
        }
      }

      return leaves;
    };

    return collectLeafNodes(parent, []);
  }

  /**
   * Get the children of a parent.
   * The children are the filter models that have the parent as their parent.
   *
   * @param {string} parent - The parent to get the children for.
   * @returns {import("../../../../shared/models/model").Model[] | []}
   */
  function getChildrenByParent(parent) {
    return modelsGroupedByParent.value.get(parent) ?? [];
  }

  /**
   * Active the parent for the given model.
   * If the model has a parent, the parent is pushed to the parents array.
   * Otherwise, the parents array is updated to only contain the root.
   */
  function activeParentForModel(model) {
    const parent = model?.parent ?? root;
    const index = parents.value.indexOf(parent);

    if (index === -1) {
      pushParent(parent);
    } else {
      parents.value = parents.value.slice(0, index + 1);
    }
  }

  /**
   * Push a parent to the parents array.
   * Once a parent is pushed, the filter list models are updated based on the given parent.
   *
   * @param {String} parent - The parent to push to the parents array
   * @returns {void}
   */
  function pushParent(parent) {
    parents.value.push(parent);
  }

  /**
   * Pop a parent from the parents array.
   * Once a parent is popped, the filter list models are updated based on the previous parent.
   *
   * @param {boolean} [drain=false] - If true, the parents array is reset to only contain the root.
   * @returns {void}
   */
  function popParent(drain = false) {
    if (drain) {
      parents.value = [root];
    } else {
      parents.value.pop();
    }
  }

  /**
   * Watch for changes in the parents array.
   * When the parents array changes, the filter list models are updated based on the current parent.
   * The progress is also recalculated.
   *
   * The watch is deep, so it detects changes such as pop and push.
   */
  watch(
    parents,
    () => {
      models.value = getChildrenByParent(currentParent.value);
    },
    { deep: true },
  );

  /**
   * The progress is calculated based on the current parent and the parents array as a number between 0 and 100.
   *
   * If a model is selected, the progress is 100.
   * If the current parent is the root, the progress is 10.
   * Otherwise, the progress is calculated based on the current parent and the parents array.
   *
   * @returns {import("vue").ComputedRef<number>}
   */
  const progress = computed(() => {
    // A selected model means that the user has completed the filter process
    if (selectedModel.value) {
      return 100;
    }

    const parentIndex = Math.max(parents.value.indexOf(currentParent.value), 0);
    const progressStep = 100 / parents.value.length;
    const progress = parentIndex * progressStep;

    if (progress > 0) {
      return progress;
    }

    // As per @jbraem, we return 10 as the minimum progress in order to show the user that there are more steps
    return 10;
  });

  return {
    // state
    loading,
    error,
    stepTitle,
    models,
    selectedModel,
    hasParent,
    progress,
    searchQuery,
    hasSearchQuery,

    // actions
    load,
    pushParent,
    popParent,
    findByValue,
    search,
  };
};
